<template>
  <body>
    <div id="qqq">
      <div id="qrCode" ref="qrCodeDiv"></div>
    </div>
  </body>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  props:{
    url:{
      type:String,
      default:''
    }
  },
  name: "qrCode",
  data() {
    return {
      newUrl:''
    };
  },
  mounted() {
    this.bindQRCode();
  },
  watch: {
    url: {
      handler(val) {
        if (val) {
          const target = {};
          const handler = {
            set(target, prop, value, receiver) {
              if (prop === 'url') {
                return true; // 或者其他真值，例如 return value;
              }
              target[prop] = value;
              return true; // 或者其他真值，例如 return value;
            }
          }
          const proxy = new Proxy(target, handler);
          proxy.url = val; // 这将正常工作，不会抛出错误
          // this.url = val
          this.$refs.qrCodeDiv.innerHTML = "";
          this.bindQRCode();
        }
      },
      deep: true
    }
  },
  methods: {
    bindQRCode() {
      new QRCode(this.$refs.qrCodeDiv, {
        text: this.url,
        width: 300,
        height: 300,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
  },
};
</script>
<style lang='scss'>
#qqq {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  position: relative;
  background: #EFEFEF;
  #qrCode {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    top: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>