<template>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>广告位置:</span>
        <el-select v-model="option.bannerPosition" clearable placeholder="请选择广告位置">
          <el-option v-for="item in data.bannerPositionListData" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div>
        <span>广告类型:</span>
        <el-select v-model="option.bannerType" clearable placeholder="请选择广告类型">
          <el-option v-for="item in data.bannerTypeListData" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div>
        <span>活动状态:</span>
        <el-select v-model="option.status" clearable placeholder="请选择活动状态">
          <el-option v-for="item in data.statusListData" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
        <el-button icon="el-icon-document-add" @click="AddClick" type="primary">新增广告</el-button>
        <el-button @click="setMessage" type="primary">配置提示信息</el-button>
      </div>
    </div>

    <!--2. table表格的内容 data.table[option.page] -->
    <el-table stripe :data="data.table" :header-cell-style="{ background: 'rgb(242, 242, 242)', color: '#333' }" v-loading="tableLoading" element-loading-text="拼命加载中...">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column>
      <!--隐藏列-->
      <el-table-column type="index" :index="indexMethod" width="50" style="text-align: center">
      </el-table-column>
      <el-table-column prop="bannerName" label="广告名称"> </el-table-column>
      <el-table-column label="广告位置">
        <template #default="scope">
          <span>{{ scope.row["bannerPosition"] == 1 ? "开屏广告" : scope.row["bannerPosition"] == 2 ? "四屏广告" : "" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="bannerCity" label="适用城市" show-overflow-tooltip></el-table-column>
      <el-table-column label="广告类型">
        <template #default="scope">
          <div>{{ scope.row["bannerType"] == 1 ? "领券" : scope.row["bannerType"] == 2 ? "报名" : scope.row["bannerType"] == 3 ? "推荐好友" : scope.row["bannerType"] == 4 ? "门店预约" : "" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="活动状态">
        <template #default="scope">
          <div>{{ scope.row["status"] == 1 ? "启用" : scope.row["status"] == 2 ? "禁用" : "" }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="creationTime" label="创建时间"></el-table-column>
      <el-table-column fixed="right" label="操作" width="550">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button size="small"  type="primary" v-if="scope.row.bannerType == 2" @click="showCode(scope.row)">签到码</el-button>
          <el-button size="small" icon="el-icon-edit" @click="UpdateClick(scope.row), lookDetail = 'edit'" type="primary" v-if="!(judge(scope.row.taskStartTime, scope.row.taskEndTime) && scope.row.status == 1)">编辑</el-button>
          <el-button size="small" @click="UpdateClick(scope.row), lookDetail = 'look'" type="primary" v-else>查看</el-button>
          <el-popconfirm title="您确定要删除吗？" confirm-button-text="是" cancel-button-text="否" iconColor="red" @confirm="deleteClick(scope.row)" v-if="!(judge(scope.row.taskStartTime, scope.row.taskEndTime) && scope.row.status == 1)">
            <template #reference>
              <el-button size="small" icon="el-icon-delete" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
          <!--<el-button size="small" icon="el-icon-close" type="primary" @click="closeStatus(scope.row)">关闭活动</el-button>-->
          <el-button size="small" icon="el-icon-close" type="primary" @click="closeStatus(scope.row)">
            {{ scope.row["status"] == 1 ? "禁用" : scope.row["status"] == 2 ? "启用" : "" }}
          </el-button>
          <el-button 
            @click="ViewDetail(scope.row)" 
            type="primary" 
            size="small" 
            v-if="scope.row.bannerType == 1 || scope.row.bannerType == 2 || scope.row.bannerType == 3"
          >
            查看{{ scope.row["bannerType"] == 1 ? "领券" : scope.row["bannerType"] == 2 ? "报名" : scope.row["bannerType"] == 3 ? "推荐好友" : "" }}
          </el-button>
          <el-button size="small" type="primary" v-if="scope.row.bannerType == 1" @click="countDetail(scope.row)">券数量</el-button>
          <el-button size="small"  type="primary" v-if="scope.row.bannerType == 2" @click="oldWithNewFun(scope.row)">查看老带新</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <div class="paginationBox">
      <el-pagination 
        @current-change="handleCurrentChange" 
        :current-page="option.page" 
        :page-size="10"
        prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;" next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;" 
        background
        layout="total, prev, pager, next, jumper" 
        :total="data.total"
      >
      </el-pagination>
    </div>

    <!-- 4.更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog width="65%" title="广告管理信息" @close="resetForm('ruleForm')" v-model="ruleForm.dialogFormVisible" :show-close="true" top="5vh">
      <el-scrollbar max-height="75vh" style="padding-right: 20px;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="180px" class="demo-ruleForm">
          <el-row>
            <el-col :span="12">
              <el-form-item prop="bannerName" label="广告名称">
                <el-input v-model="ruleForm.bannerName" clearable placeholder="请填写广告名称" :disabled="lookDetail == 'look'"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="bannerCity" label="适用城市">
                <el-select v-model="ruleForm.bannerCity" multiple filterable placeholder="请选择适用城市" @change="bannerCityChange" :disabled="lookDetail == 'look'" style="width: 100%;">
                  <el-option v-for="item in data.cityListData" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item prop="bannerType" label="广告类型">
                <el-select v-model="ruleForm.bannerType" clearable placeholder="请选择广告类型" @change="positionChange" :disabled="lookDetail == 'look'" style="width: 100%;">
                  <el-option v-for="item in data.bannerTypeListData" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="分享标题" required prop="shareTitle" v-if="ruleForm.bannerType == 3">
                <el-input v-model="ruleForm.shareTitle" clearable placeholder="请输入分享标题" :maxlength="50" autocomplete="off" :disabled="lookDetail == 'look'" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 转发 -->
          <el-row style="margin-bottom: 20px">
            <el-form-item label="转发图片" prop="transferImg" label-width="170px" v-if="ruleForm.bannerType == 3">
              <el-upload ref="myupload" 
                action="" 
                :file-list="ruleForm.transferImgList" 
                :beforeUpload="beforeUpload" 
                :http-request="transferHandleUpload" 
                :multiple="false"
                :limit="1"
                accept=".jpeg,.jpg">
                <el-button size="small" type="primary" :disabled="lookDetail == 'look'">上传转发图片</el-button>
              </el-upload>
              <el-col>
                <p style="color: red;" v-if="lookDetail != 'look'">仅支持.jpg、.jpeg格式的图片，图片长宽比5:4，大小不超过2MB</p>
              </el-col>
              <!--看上传的图片-->
              <div v-if="ruleForm.transferImageDiv">
                <el-image :src="ruleForm.transferImg" fit="cover" style="width: 400px"></el-image>
                <el-button @click="imageClose" v-if="ruleForm.transferImg" style="position: absolute; margin-left: -40px" type="danger" icon="el-icon-close" circle></el-button>
              </div>
            </el-form-item>
          </el-row>
          <el-row style="margin-bottom: 20px">
            <el-col :span="16">
              <el-form-item label="转发描述" prop="transferDescribe" label-width="170px" v-if="ruleForm.bannerType == 3">
                <el-input v-model="ruleForm.transferDescribe" type="textarea" :rows="2" clearable placeholder="请输入转发描述" autocomplete="off" :maxlength="25" :disabled="lookDetail == 'look'"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 分享 -->
          <el-row style="margin-bottom: 20px">
            <el-form-item label="分享图片" prop="shareImage" label-width="170px" v-if="ruleForm.bannerType == 3">
              <el-upload ref="myupload" 
                action="" 
                :file-list="ruleForm.fileLists" 
                :on-preview="handlePreviews"
                :on-remove="handleRemoves" 
                :beforeUpload="beforeUpload" 
                :http-request="handleUploads" 
                :multiple="false"
                :limit="1"
                accept=".png,.jpg">
                <el-button size="small" type="primary" :disabled="lookDetail == 'look'">上传分享图片</el-button>
              </el-upload>
              <el-col>
                <p style="color: red;" v-if="lookDetail != 'look'">图片格式长宽比5:4;大小不超过 2MB; 扩展名:“png.jpg.jpeg"</p>
              </el-col>
              <!--看上传的图片-->
              <div v-if="ruleForm.imageDivs">
                <el-image :src="ruleForm.shareImage" fit="cover" style="width: 400px"></el-image>
                <el-button @click="imageClose" v-if="ruleForm.shareImage" style="position: absolute; margin-left: -40px" type="danger" icon="el-icon-close" circle></el-button>
              </div>
            </el-form-item>
          </el-row>
          <el-row style="margin-bottom: 20px">
            <el-col :span="16">
              <el-form-item label="分享描述" prop="shareDescription" label-width="170px" v-if="ruleForm.bannerType == 3">
                <el-input v-model="ruleForm.shareDescription" type="textarea" :rows="6" clearable placeholder="分享描述" autocomplete="off" :maxlength="500" :disabled="lookDetail == 'look'"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="bannerPosition" label="广告位置">
                <el-select v-model="ruleForm.bannerPosition" clearable placeholder="请选择广告位置" @focus="positionFocus" :disabled="lookDetail == 'look'">
                  <el-option v-for="item in reData.bannerPositionListData" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item prop="taskTime" label="活动时间">
                <el-date-picker v-if="ruleForm.bannerType == 2" v-model="ruleForm.taskTime" type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期" placeholder="选择日期" :disabled="lookDetail == 'look'" @change="taskChange"></el-date-picker>
                <el-date-picker v-else v-model="ruleForm.taskTime" type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期" placeholder="选择日期" :disabled="lookDetail == 'look'" :disabled-date="taskTimeOptions" @change="taskChange"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否启用" prop="status">
                <el-radio-group v-model="ruleForm.status">
                  <el-radio label="1" :disabled="lookDetail == 'look'">启用</el-radio>
                  <el-radio label="2" :disabled="lookDetail == 'look'">禁用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" v-if="lookDetail != 'look'">
              <!-- :on-exceed="handleExceed" -->
              <el-form-item prop="bannerImgURL" :label="ruleForm.bannerType == 3 ? '邀请人背景' : '上传广告图'">
                <el-upload 
                  :disabled="ruleForm.upload"
                  action="" 
                  :file-list="ruleForm.fileList"
                  :multiple="false" 
                  :limit="1"
                  accept=".png,.jpg,.jpeg" 
                  list-type="picture-card" 
                  :beforeUpload="beforeUpload" 
                  :on-preview="handlePreview"
                  :http-request="handleUpload">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog v-model="reData.dialogVisible">
                  <el-image :src="ruleForm.bannerImgURL"></el-image>
                </el-dialog>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-else>
              <el-form-item prop="bannerImgURL" :label="ruleForm.bannerType == 3 ? '邀请人背景' : '上传广告图'">
                <div style="color: #999;text-align: center;margin-top: 50px;">查看详情无法修改</div>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.addOrUpdate == 1 && ruleForm.img && ruleForm.bannerImgURL">
              <el-image style="width: 50%; height: 50%" :src="ruleForm.bannerImgURL" fit="contain" @click="handlePreview"></el-image>
              <span @click="handleImgDelete" v-if="lookDetail != 'look'">
                <i class="el-icon-delete"></i>
              </span>
            </el-col>
            <el-col v-if="lookDetail != 'look'">
              <p style="color: red; padding-left: 100px; padding-bottom: 20px">图片最多一张宽: 750px; 高:不限; 大小不超过2MB; 扩展名:".png，.jPg，.jpeg"</p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" v-if="lookDetail != 'look'">
              <el-form-item prop="bannerImgURLKp" :label="ruleForm.bannerType == 3 ? '被邀请人背景' : '上传背景图'">
                <el-upload 
                  :disabled="ruleForm.uploadKp" 
                  action="" 
                  :file-list="ruleForm.fileListKp" 
                  :multiple="false"
                  :limit="1" accept=".png,.jpg,.jpeg" 
                  list-type="picture-card" 
                  :beforeUpload="beforeUpload"
                  :on-preview="handlePreviewKp" 
                  :http-request="handleUploadKp">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog v-model="reData.dialogVisibleKp">
                  <el-image :src="ruleForm.bannerImgURLKp"></el-image>
                </el-dialog>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-else>
              <el-form-item prop="bannerImgURLKp" :label="ruleForm.bannerType == 3 ? '被邀请人背景' : '上传背景图'">
                <div style="color: #999;text-align: center;margin-top: 50px;">查看详情无法修改</div>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.addOrUpdate == 1 && ruleForm.imgKp && ruleForm.bannerImgURLKp">
              <el-image style="width: 50%; height: 50%" :src="ruleForm.bannerImgURLKp" fit="contain" @click="handlePreviewKp"></el-image>
              <span @click="handleImgDeleteKp" v-if="lookDetail != 'look'">
                <i class="el-icon-delete"></i>
              </span>
            </el-col>
            <el-col v-if="lookDetail != 'look'">
              <p style="color: red; padding-left: 100px; padding-bottom: 20px">图片最多一张宽: 750px; 高:不限; 大小不超过2MB; 扩展名:".png，.jPg，.jpeg"</p>
            </el-col>
          </el-row>
          <div v-if="ruleForm.bannerType == 1">
            <el-row>领券规则设置</el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item prop="peopleDayMaxCount" label="每人每天领取最大数量">
                  <el-input type="number" v-model="ruleForm.peopleDayMaxCount" clearable placeholder="如果是0就不限制抽奖次数" @input="(val) => { isNumber(val, 1); }" :disabled="lookDetail == 'look'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="dayMaxCount" label="每天最多领券数量">
                  <el-input type="number" v-model="ruleForm.dayMaxCount" clearable placeholder="如果是0就不限制抽奖次数" @input="(val) => { isNumber(val, 2); }" :disabled="lookDetail == 'look'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12"></el-col>
              <el-col :span="12">
                <el-form-item prop="peopleMaxCount" label="每人最多领券数量">
                  <el-input type="number" v-model="ruleForm.peopleMaxCount" clearable placeholder="如果是0就不限制抽奖次数" @input="(val) => { isNumber(val, 3); }" :disabled="lookDetail == 'look'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-if="reData.vipManageFlag">
              <template v-for="(item, index) in reData.vipManageList" :key="item.id">
                <el-row v-if="index % 2 == 0">
                  <el-col :span="12">
                    <el-form-item prop="couponLeft" :label="item.vipName">
                      <el-select v-model="ruleForm.coupon[index]" multiple filterable placeholder="请选择可领用券" @focus="couponFocus" :disabled="lookDetail == 'look'">
                        <el-option v-for="item in reData.couponListData" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="index + 1 < reData.vipManageList.length">
                    <el-form-item prop="couponRight" :label="reData.vipManageList[index + 1].vipName">
                      <el-select v-model="ruleForm.coupon[index + 1]" multiple filterable placeholder="请选择可领用券" @focus="couponFocus" :disabled="lookDetail == 'look'">
                        <el-option v-for="item in reData.couponListData" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </div>
          </div>
          <div v-if="ruleForm.bannerType == 2">
            <el-row style="margin-bottom: 20px">报名活动信息</el-row>
            <!-- <el-row>
              <el-form-item prop="peopleMaxCount">
                <el-checkbox :indeterminate="reData.isIndeterminate" v-model="reData.checkAll" @change="handleCheckAllChange" :disabled="lookDetail == 'look'">全选</el-checkbox>
                <el-checkbox-group v-model="ruleForm.applicationTasks" @change="handleCheckedCitiesChange" :disabled="lookDetail == 'look'">
                  <el-checkbox v-for="item in reData.applicationTasks" :label="item.id" :key="item.id">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-row> -->
            <el-row style="margin-bottom: 20px">
              <el-col :span="16">
                <el-form-item label="活动描述" prop="activityDescription" label-width="170px">
                  <QuillEditor ref="quillEditorRef" v-model="ruleForm.activityDescription"></QuillEditor>
                  <!-- <el-input v-model="ruleForm.activityDescription" type="textarea" :rows="4" clearable placeholder="活动描述" autocomplete="off" :maxlength="200" :disabled="lookDetail == 'look'"></el-input> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 20px">
              <el-col :span="16">
                <el-form-item label="活动地点" prop="venue" label-width="170px">
                  <el-input v-model="ruleForm.venue" type="textarea" :rows="2" clearable placeholder="活动地点" autocomplete="off" :maxlength="100" :disabled="lookDetail == 'look'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 20px">
              <el-col :span="16">
                <el-form-item prop="applyTime" label="举办时间">
                  <el-date-picker v-if="ruleForm.bannerType == 2" v-model="ruleForm.applyTime" type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期" placeholder="选择举办日期" :disabled="lookDetail == 'look'" @change="applyTimeChange"></el-date-picker>
                  <el-date-picker v-else v-model="ruleForm.applyTime" type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期" placeholder="选择举办日期" :disabled="lookDetail == 'look'" :disabled-date="taskTimeOptions" @change="applyTimeChange"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 20px">
              <el-col :span="16">
                <el-form-item label="主办方电话" label-width="170px">
                  <el-input v-model="ruleForm.sponsorPhoneNum" type="textarea" :rows="1" clearable placeholder="主办方电话" autocomplete="off" :maxlength="20" :disabled="lookDetail == 'look'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 20px">
              <el-col :span="16">
                <!--  prop="couponIds" -->
                <el-form-item label="优惠券">
                  <el-col :span="8">
                      <el-select 
                        :disabled="lookDetail == 'look'"
                        v-model="ruleForm.couponIds"
                        multiple
                        filterable
                        placeholder="请选择优惠券"
                        @click="getCouponsListClick"
                      >
                        <el-option
                          v-for="item in data.couponsList"
                          :key="item.coupon_id"
                          :label="item.coupon_name"
                          :value="item.coupon_id"
                        >
                        </el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
  
          <!-- 邀请好友调整 -->
          <div v-if="ruleForm.bannerType == 3">
            <div class="text-size-16px font-600">邀请规则</div>
            <el-row>
              <el-col :span="12">
                <el-form-item prop="participants" label="邀请人等级">
                  <el-select v-model="ruleForm.participants" multiple placeholder="请选择参与邀请活动会员等级" class="w-full" clearable :disabled="lookDetail == 'look'">
                    <el-option v-for="item in data.VipListData" :key="item.id" :label="item.vipName" :value="item.vipName" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item prop="maxInviteNumber" label="最多邀请人数">
                  <div class="flex items-center">
                    <el-input v-model="ruleForm.maxInviteNumber" @input="ruleForm.maxInviteNumber = ruleForm.maxInviteNumber.replace(/^[0]|[^\d]/g, '');" :disabled="lookDetail == 'look'" :maxlength="8" placeholder="请输入人数" />
                    <el-tooltip class="box-item" effect="dark" content="邀请人最多邀请几人" placement="bottom">
                      <el-icon class="ml-5px" :size="16"><QuestionFilled /></el-icon>
                    </el-tooltip>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="" label="最少邀请人数">
                  <div class="flex items-center">
                    <el-input v-model="ruleForm.minInviteNumber" @input="ruleForm.minInviteNumber = ruleForm.minInviteNumber.replace(/^[0]|[^\d]/g, '');" :maxlength="8" :disabled="lookDetail == 'look'" placeholder="请输入人数" />
                    <el-tooltip class="box-item" effect="dark" :content="`最少邀请${ruleForm.minInviteNumber}人才会发放奖励`" placement="bottom">
                      <el-icon class="ml-5px" :size="16"><QuestionFilled /></el-icon>
                    </el-tooltip>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
    
            <!-- 被邀请人注册 -->
            <div class="text-size-16px font-600">被邀请人注册</div>
            <div class="px-30px py-20px mt-30px border-solid border-1px border-gray-300 yqr_box">
              <el-tabs v-model="activeNameYqr" class="demo-tabs">
                <!-- 邀请人奖励 -->
                <el-tab-pane label="邀请人奖励" name="yqrFirst">
                  <div class="border-solid border-1px border-gray-200">
                    <el-row class="border_bottom px-30px py-10px flex items-center">
                      <el-col :span="3">序号</el-col>
                      <el-col :span="6">赠送类型</el-col>
                      <el-col :span="6">名称</el-col>
                      <el-col :span="6">数量</el-col>
                      <el-col :span="3" class="flex justify-end">
                        <el-button type="primary" @click="addInviterAward({}, 'zcYqrAward')" v-if="lookDetail != 'look'">添加</el-button>
                      </el-col>
                    </el-row>
                    <div class="noData" v-if="!ruleForm.registerRule.inviteRewards.length">暂无数据</div>
                    <el-row class="border_bottom px-30px py-10px flex items-center" v-for="(item, index) in ruleForm.registerRule.inviteRewards" :key="index">
                      <el-col :span="3">{{ index + 1 }}</el-col>
                      <el-col :span="6" class="pr-20px">
                        <el-form-item :label-width="0" :prop="`registerRule.inviteRewards.${index}.rewardType`" :rules="rules.inviteRewards.rewardType">
                          <el-select v-model="item.rewardType" placeholder="请选择赠送类型" :disabled="lookDetail == 'look'">
                            <el-option label="积分" :value="1" />
                            <el-option label="优惠券" :value="2" />
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6" class="pr-20px">
                        <div v-if="item.rewardType == 1">积分</div>
                        <el-form-item :label-width="0" :prop="`registerRule.inviteRewards.${index}.couponInformation`" :rules="rules.inviteRewards.couponInformation" v-if="item.rewardType == 2">
                          <el-select v-model="item.couponInformation" filterable placeholder="请选择优惠券" @focus="couponFocus" :disabled="lookDetail == 'look'">
                            <el-option v-for="item in reData.couponListData" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6" class="pr-20px">
                        <el-form-item :label-width="0" :prop="`registerRule.inviteRewards.${index}.accumulatePoints`" :rules="rules.inviteRewards.accumulatePoints" v-if="item.rewardType == 1">
                          <el-input :disabled="lookDetail == 'look'" v-model="item.accumulatePoints" @input="item.accumulatePoints = item.accumulatePoints.replace(/^[0]|[^\d]/g, '');" :maxlength="8" placeholder="请输入积分数量" />
                        </el-form-item>
                        <el-form-item :label-width="0" :prop="`registerRule.inviteRewards.${index}.couponNumber`" :rules="rules.inviteRewards.couponNumber" v-if="item.rewardType == 2">
                          <el-input :disabled="lookDetail == 'look'" v-model="item.couponNumber" @input="item.couponNumber = item.couponNumber.replace(/^[0]|[^\d]/g, '');" :maxlength="8" placeholder="请输入优惠券数量" />
                        </el-form-item>
                      </el-col>
                      <!-- 新增-删除 -->
                      <el-col :span="3" class="flex justify-end" v-if="lookDetail == 'add' || !item.inviteRewardConfigId">
                        <el-button type="danger" plain @click="delInviterAward(item, index, 'zcYqrAward')">删除</el-button>
                      </el-col>
                      <!-- 编辑-删除 -->
                      <el-col :span="3" class="flex justify-end" v-if="lookDetail == 'edit' && item.inviteRewardConfigId">
                        <el-popconfirm title="是否确认删除该条邀请人奖励？" confirm-button-text="确认" cancel-button-text="取消" @confirm="delInviterAward(item, index, 'zcYqrAward')" v-if="lookDetail != 'look'">
                          <template #reference>
                            <el-button type="danger" plain>删除</el-button>
                          </template>
                        </el-popconfirm>
                      </el-col>
                    </el-row>
                  </div>
                </el-tab-pane>
                <!-- 被邀请人奖励 -->
                <el-tab-pane label="被邀请人奖励" name="yqrSecond">
                  <div class="border-solid border-1px border-gray-200">
                    <el-row class="border_bottom px-30px py-10px flex items-center">
                      <el-col :span="3">序号</el-col>
                      <el-col :span="6">赠送类型</el-col>
                      <el-col :span="6">名称</el-col>
                      <el-col :span="6">数量</el-col>
                      <el-col :span="3" class="flex justify-end">
                        <el-button type="primary" @click="addInviterAward({}, 'zcByqrAward')" v-if="lookDetail != 'look'">添加</el-button>
                      </el-col>
                    </el-row>
                    <div class="noData" v-if="!ruleForm.registerRule.invitedRewards.length">暂无数据</div>
                    <el-row class="border_bottom px-30px py-10px flex items-center" v-for="(item, index) in ruleForm.registerRule.invitedRewards" :key="index">
                      <el-col :span="3">{{ index + 1 }}</el-col>
                      <el-col :span="6" class="pr-20px">
                        <el-form-item :label-width="0" :prop="`registerRule.invitedRewards.${index}.rewardType`" :rules="rules.invitedRewards.rewardType">
                          <el-select v-model="item.rewardType" placeholder="请选择赠送类型" :disabled="lookDetail == 'look'">
                            <el-option label="积分" :value="1" />
                            <el-option label="优惠券" :value="2" />
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6" class="pr-20px">
                        <div v-if="item.rewardType == 1">积分</div>
                        <el-form-item :label-width="0" :prop="`registerRule.invitedRewards.${index}.couponInformation`" :rules="rules.invitedRewards.couponInformation" v-if="item.rewardType == 2">
                          <el-select v-model="item.couponInformation" filterable placeholder="请选择优惠券" @focus="couponFocus" :disabled="lookDetail == 'look'">
                            <el-option v-for="item in reData.couponListData" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6" class="pr-20px">
                        <el-form-item :label-width="0" :prop="`registerRule.invitedRewards.${index}.accumulatePoints`" :rules="rules.invitedRewards.accumulatePoints"  v-if="item.rewardType == 1">
                          <el-input :disabled="lookDetail == 'look'" v-model="item.accumulatePoints" @input="item.accumulatePoints = item.accumulatePoints.replace(/^[0]|[^\d]/g, '');" :maxlength="8" placeholder="请输入积分数量" />
                        </el-form-item>
                        <el-form-item :label-width="0" :prop="`registerRule.invitedRewards.${index}.rewardType`" :rules="rules.invitedRewards.couponNumber" v-if="item.rewardType == 2">
                          <el-input :disabled="lookDetail == 'look'" v-model="item.couponNumber" @input="item.couponNumber = item.couponNumber.replace(/^[0]|[^\d]/g, '');" :maxlength="8" placeholder="请输入优惠券数量" />
                        </el-form-item>
                      </el-col>
                      <!-- 新增-删除 -->
                      <el-col :span="3" class="flex justify-end" v-if="lookDetail == 'add' || !item.inviteRewardConfigId">
                        <el-button type="danger" plain @click="delInviterAward(item, index, 'zcByqrAward')">删除</el-button>
                      </el-col>
                      <!-- 编辑-删除 -->
                      <el-col :span="3" class="flex justify-end" v-if="lookDetail == 'edit' && item.inviteRewardConfigId">
                        <el-popconfirm title="是否确认删除该条被邀请人奖励？" confirm-button-text="确认" cancel-button-text="取消" @confirm="delInviterAward(item, index, 'zcByqrAward')" v-if="lookDetail != 'look'">
                          <template #reference>
                            <el-button type="danger" plain>删除</el-button>
                          </template>
                        </el-popconfirm>
                      </el-col>
                    </el-row>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
    
            <!-- 被邀请人消费 -->
            <div class="text-size-16px font-600 flex items-center mt-30px">
              被邀请人消费
              <el-tooltip class="box-item" effect="dark" content="被邀请人注册成功，并消费一定金额，会发放不同的奖励" placement="bottom">
                <el-icon class="ml-5px" :size="16"><QuestionFilled /></el-icon>
              </el-tooltip>
            </div>
            <div class="border-solid border-1px border-gray-200 px-30px py-20px my-20px gz_box">
              <div class="flex justify-end mb-20px">
                <el-button type="primary" @click="addConsumptionRule" v-if="lookDetail != 'look'">添加消费规则</el-button>
              </div>
    
              <!-- 消费规则 -->
              <div class="noData" v-if="!ruleForm.consumeRules.length">暂无消费规则</div>
              <div class="mt-30px" v-for="(item, indexRule) in ruleForm.consumeRules" :key="item.value">
                <el-divider />
                <div class="flex items-center mb-20px">
                  <div>{{ `${indexRule + 1}. 消费金额` }}</div>
                  <div class="flex items-center ml-10px">
                    <el-form-item :label-width="0" :prop="`consumeRules.${indexRule}.amountStart`" :rules="rules.consumeRules.amountStart">
                      <el-input v-model="item.amountStart" :disabled="lookDetail == 'look'" @blur="changeAmountMax(item)" @input="item.amountStart = item.amountStart.match(/\d+(\.\d{0,2})?/) ? item.amountStart.match(/\d+(\.\d{0,2})?/)[0] : ''" :maxlength="8" placeholder="金额" />
                    </el-form-item>
                    <div class="px-10px">至</div>
                    <el-form-item :label-width="0" :prop="`consumeRules.${indexRule}.amountEnd`" :rules="rules.consumeRules.amountEnd">
                      <el-input v-model="item.amountEnd" :disabled="lookDetail == 'look'" @blur="changeAmountMax(item)" @input="item.amountEnd = item.amountEnd.match(/\d+(\.\d{0,2})?/) ? item.amountEnd.match(/\d+(\.\d{0,2})?/)[0] : ''" :maxlength="8" placeholder="金额" />
                    </el-form-item>
                  </div>
                  <div class="ml-10px" v-if="lookDetail == 'add' || !item.inviteRuleConfigId">
                    <el-button type="danger" plain @click="delConsumptionRule(item, index)" v-if="lookDetail != 'look'">删除规则</el-button>
                  </div>
                  <div class="ml-10px" v-if="lookDetail == 'edit' && item.inviteRuleConfigId">
                    <el-popconfirm title="是否确认删除该条消费规则？" confirm-button-text="确认" cancel-button-text="取消" @confirm="delConsumptionRule(item, index)" v-if="lookDetail != 'look'">
                      <template #reference>
                        <el-button type="danger" plain>删除规则</el-button>
                      </template>
                    </el-popconfirm>
                  </div>
                </div>
                <el-tabs v-model="item.activeName" class="demo-tabs">
                  <!-- 邀请人奖励 -->
                  <el-tab-pane label="邀请人奖励" :name="`firstRule${indexRule}`">
                    <div class="border-solid border-1px border-gray-200 border-b-0">
                      <el-row class="border_bottom px-30px py-10px flex items-center">
                        <el-col :span="3">序号</el-col>
                        <el-col :span="6">赠送类型</el-col>
                        <el-col :span="6">名称</el-col>
                        <el-col :span="6">数量</el-col>
                        <el-col :span="3" class="flex justify-end">
                          <el-button type="primary" @click="addInviterAward(item, 'xfYqrAward')" v-if="lookDetail != 'look'">添加</el-button>
                        </el-col>
                      </el-row>
                      <div class="noData" v-if="!item.inviteRewards.length">暂无数据</div>
                      <el-row class="border_bottom px-30px py-10px flex items-center" v-for="(val, index) in item.inviteRewards" :key="index">
                        <el-col :span="3">{{ index + 1 }}</el-col>
                        <el-col :span="6" class="pr-20px">
                          <el-form-item :label-width="0" :prop="`consumeRules.${indexRule}.inviteRewards.${index}.rewardType`" :rules="rules.consumeRules.inviteRewards.rewardType">
                            <el-select v-model="val.rewardType" placeholder="请选择赠送类型" :disabled="lookDetail == 'look'">
                              <el-option label="积分" :value="1" />
                              <el-option label="优惠券" :value="2" />
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6" class="pr-20px">
                          <div v-if="val.rewardType == 1">积分</div>
                          <el-form-item :label-width="0" :prop="`consumeRules.${indexRule}.inviteRewards.${index}.couponInformation`" :rules="rules.consumeRules.inviteRewards.couponInformation" v-if="val.rewardType == 2">
                            <el-select v-model="val.couponInformation" filterable placeholder="请选择优惠券" @focus="couponFocus" :disabled="lookDetail == 'look'">
                              <el-option v-for="item in reData.couponListData" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id"></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6" class="pr-20px">
                          <el-form-item :label-width="0" :prop="`consumeRules.${indexRule}.inviteRewards.${index}.accumulatePoints`" :rules="rules.consumeRules.inviteRewards.accumulatePoints" v-if="val.rewardType == 1">
                            <el-input :disabled="lookDetail == 'look'" v-model="val.accumulatePoints" @input="val.accumulatePoints = val.accumulatePoints.replace(/^[0]|[^\d]/g, '');" :maxlength="8" placeholder="请输入积分数量" />
                          </el-form-item>
                          <el-form-item :label-width="0" :prop="`consumeRules.${indexRule}.inviteRewards.${index}.couponNumber`" :rules="rules.consumeRules.inviteRewards.couponNumber" v-if="val.rewardType == 2">
                            <el-input :disabled="lookDetail == 'look'" v-model="val.couponNumber" @input="val.couponNumber = val.couponNumber.replace(/^[0]|[^\d]/g, '');" :maxlength="8" placeholder="请输入优惠券数量" />
                          </el-form-item>
                        </el-col>
                        <!-- 新增-删除 -->
                        <el-col :span="3" class="flex justify-end" v-if="lookDetail == 'add' || !val.inviteRewardConfigId">
                          <el-button type="danger" plain @click="delInviterAward(item, index, 'xfYqrAward')">删除</el-button>
                        </el-col>
                        <!-- 编辑-删除 -->
                        <el-col :span="3" class="flex justify-end" v-if="lookDetail == 'edit' && val.inviteRewardConfigId">
                          <el-popconfirm title="是否确认删除该条邀请人奖励？" confirm-button-text="确认" cancel-button-text="取消" @confirm="delInviterAward(item, index, 'xfYqrAward')" v-if="lookDetail != 'look'">
                            <template #reference>
                              <el-button type="danger" plain>删除</el-button>
                            </template>
                          </el-popconfirm>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tab-pane>
                  <!-- 被邀请人奖励 -->
                  <el-tab-pane label="被邀请人奖励" :name="`secondRule${indexRule}`">
                    <div class="border-solid border-1px border-gray-200 border-b-0">
                      <el-row class="border_bottom px-30px py-10px flex items-center">
                        <el-col :span="3">序号</el-col>
                        <el-col :span="6">赠送类型</el-col>
                        <el-col :span="6">名称</el-col>
                        <el-col :span="6">数量</el-col>
                        <el-col :span="3" class="flex justify-end">
                          <el-button type="primary" @click="addInviterAward(item, 'xfByqrAward')" v-if="lookDetail != 'look'">添加</el-button>
                        </el-col>
                      </el-row>
                      <div class="noData" v-if="!item.invitedRewards.length">暂无数据</div>
                      <el-row class="border_bottom px-30px py-10px flex items-center" v-for="(val, index) in item.invitedRewards" :key="index">
                        <el-col :span="3">{{ index + 1 }}</el-col>
                        <el-col :span="6" class="pr-20px">
                          <el-form-item :label-width="0" :prop="`consumeRules.${indexRule}.invitedRewards.${index}.rewardType`" :rules="rules.consumeRules.invitedRewards.rewardType">
                            <el-select v-model="val.rewardType" placeholder="请选择赠送类型" :disabled="lookDetail == 'look'">
                              <el-option label="积分" :value="1" />
                              <el-option label="优惠券" :value="2" />
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6" class="pr-20px">
                          <div v-if="val.rewardType == 1">积分</div>
                          <el-form-item :label-width="0" :prop="`consumeRules.${indexRule}.invitedRewards.${index}.couponInformation`" :rules="rules.consumeRules.invitedRewards.couponInformation" v-if="val.rewardType == 2">
                            <el-select v-model="val.couponInformation" filterable placeholder="请选择优惠券" @focus="couponFocus" :disabled="lookDetail == 'look'">
                              <el-option v-for="item in reData.couponListData" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id"></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6" class="pr-20px">
                          <el-form-item :label-width="0" :prop="`consumeRules.${indexRule}.invitedRewards.${index}.accumulatePoints`" :rules="rules.consumeRules.invitedRewards.accumulatePoints" v-if="val.rewardType == 1">
                            <el-input :disabled="lookDetail == 'look'" v-model="val.accumulatePoints" @input="val.accumulatePoints = val.accumulatePoints.replace(/^[0]|[^\d]/g, '');" :maxlength="8" placeholder="请输入积分数量" />
                          </el-form-item>
                          <el-form-item :label-width="0" :prop="`consumeRules.${indexRule}.invitedRewards.${index}.couponNumber`" :rules="rules.consumeRules.invitedRewards.couponNumber" v-if="val.rewardType == 2">
                            <el-input :disabled="lookDetail == 'look'" v-model="val.couponNumber" @input="val.couponNumber = val.couponNumber.replace(/^[0]|[^\d]/g, '');" :maxlength="8" placeholder="请输入优惠券数量" />
                          </el-form-item>
                        </el-col>
                        <!-- 新增-删除 -->
                        <el-col :span="3" class="flex justify-end"  v-if="lookDetail == 'add' || !val.inviteRewardConfigId">
                          <el-button type="danger" plain @click="delInviterAward(item, index, 'xfByqrAward')">删除</el-button>
                        </el-col>
                        <!-- 编辑-删除 -->
                        <el-col :span="3" class="flex justify-end" v-if="lookDetail == 'edit' && val.inviteRewardConfigId">
                          <el-popconfirm title="是否确认删除该条被邀请人奖励？" confirm-button-text="确认" cancel-button-text="取消" @confirm="delInviterAward(item, index, 'xfByqrAward')" v-if="lookDetail != 'look'">
                            <template #reference>
                              <el-button type="danger" plain>删除</el-button>
                            </template>
                          </el-popconfirm>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
            <!-- 邀请人规则 -->
            <el-row>
              <el-col :span="12" v-if="lookDetail != 'look'">
                <el-form-item prop="inviteImageUrl" label="邀请人活动规则">
                  <el-upload 
                    :disabled="ruleForm.uploadYqrRule" 
                    action="" 
                    :file-list="ruleForm.inviteImageUrlFileList" 
                    :multiple="false" :limit="1"
                    accept=".png,.jpg,.jpeg" 
                    list-type="picture-card" 
                    :beforeUpload="beforeUpload" 
                    :on-preview="handleYqrRule"
                    :http-request="handleUploadYqrRule">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog v-model="reData.dialogVisibleYqr">
                    <el-image :src="ruleForm.inviteImageUrl"></el-image>
                  </el-dialog>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-else>
                <el-form-item prop="inviteImageUrl" label="邀请人活动规则">
                  <div style="color: #999;text-align: center;margin-top: 50px;">查看详情无法修改</div>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="ruleForm.addOrUpdate == 1 && ruleForm.imgDetailYqr && ruleForm.inviteImageUrl">
                <el-image style="width: 50%; height: 50%" :src="ruleForm.inviteImageUrl" fit="contain" @click="handleYqrRule"></el-image>
                <span @click="handleImgDeleteYqrRule" v-if="lookDetail != 'look'">
                  <i class="el-icon-delete"></i>
                </span>
              </el-col>
              <el-col v-if="lookDetail != 'look'">
                <p style="color: red; padding-left: 100px; padding-bottom: 20px">图片最多一张宽:750px，高:不限，大小不超过2MB，扩展名：“.png，.ipg，.jpeg”</p>
              </el-col>
            </el-row>
            <!-- 被邀请人规则 -->
            <el-row>
              <el-col :span="12" v-if="lookDetail != 'look'">
                <el-form-item prop="invitedImageUrl" label="被邀请人活动规则">
                  <el-upload 
                    :disabled="ruleForm.uploadByqrRule" 
                    action="" 
                    :file-list="ruleForm.invitedImageUrlFileList" 
                    :multiple="false" 
                    :limit="1"
                    accept=".png,.jpg,.jpeg" 
                    list-type="picture-card" 
                    :beforeUpload="beforeUpload" 
                    :on-preview="handleByqrRule"
                    :http-request="handleUploadByqrRule">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog v-model="reData.dialogVisibleByqr">
                    <el-image :src="ruleForm.invitedImageUrl"></el-image>
                  </el-dialog>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-else>
                <el-form-item prop="invitedImageUrl" label="被邀请人活动规则">
                  <div style="color: #999;text-align: center;margin-top: 50px;">查看详情无法修改</div>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="ruleForm.addOrUpdate == 1 && ruleForm.imgDetailByqr && ruleForm.invitedImageUrl">
                <el-image style="width: 50%; height: 50%" :src="ruleForm.invitedImageUrl" fit="contain" @click="handleByqrRule"></el-image>
                <span @click="handleImgDeleteByqrRule" v-if="lookDetail != 'look'">
                  <i class="el-icon-delete"></i>
                </span>
              </el-col>
              <el-col v-if="lookDetail != 'look'">
                <p style="color: red; padding-left: 100px; padding-bottom: 20px">图片最多一张宽:750px，高:不限，大小不超过2MB，扩展名：“.png，.ipg，.jpeg”</p>
              </el-col>
            </el-row>
          </div>
  
          <!-- 广告详情 -->
          <el-row v-if="ruleForm.bannerType != 3">
            <el-col :span="12" v-if="lookDetail != 'look'">
              <el-form-item prop="bannerDetails" label="广告详情">
                <el-upload 
                  :disabled="ruleForm.uploadDetail" 
                  action="" 
                  :file-list="ruleForm.fileListDetail"
                  :multiple="false" 
                  :limit="1" 
                  accept=".png,.jpg,.jpeg" 
                  list-type="picture-card"
                  :beforeUpload="beforeUpload" 
                  :on-preview="handlePreviewDetail" 
                  :http-request="handleUploadDetail">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog v-model="reData.dialogVisibleDetail">
                  <el-image :src="ruleForm.bannerDetails"></el-image>
                </el-dialog>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-else>
              <el-form-item label="广告详情">
                <div style="color: #999;text-align: center;margin-top: 50px;">查看详情无法修改</div>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.addOrUpdate == 1 && ruleForm.imgDetail && ruleForm.bannerDetails">
              <el-image style="width: 50%; height: 50%" :src="ruleForm.bannerDetails" fit="contain" @click="handlePreviewDetail"></el-image>
              <span @click="handleImgDeleteDetail" v-if="lookDetail != 'look'">
                <i class="el-icon-delete"></i>
              </span>
            </el-col>
            <el-col v-if="lookDetail != 'look'">
              <p style="color: red; padding-left: 100px; padding-bottom: 20px">图片格式 宽：1280px；高：不限；大小不超过2MB；扩展名：".png,.jpg,.jpeg"</p>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <template #footer v-if="lookDetail != 'look'">
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog width="65%" title="配置提示信息" @close="resetFormMessage('ruleFormMessage')" v-model="ruleFormMessage.dialogFormVisibleMessage" :show-close="true">
      <el-form :model="ruleFormMessage" ref="ruleFormRefMessage" label-width="180px" class="demo-ruleForm">
        <el-row>
          <el-col :span="24">
            <el-form-item prop="evalContent" label="提示信息">
              <el-input type="textarea" autosize v-model="ruleFormMessage.evalContent" clearable placeholder="请配置提示信息"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetFormMessage('ruleFormMessage')">取 消</el-button>
          <el-button type="primary" @click="submitFormMessage('ruleFormMessage')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!--配置优惠券-->
    <el-dialog width="65%" title="券数量" @close="resetFormVip()" v-model="reDataForm.dialogFormVisibleVip" :show-close="true">
      <el-form :model="reDataForm" ref="ruleFormRefMessage" label-width="180px" class="demo-ruleForm">
        <el-table stripe :data="reDataForm.tableVip" style="width: 100%">
          <el-table-column prop="vipManageName" label="会员等级"></el-table-column>
          <el-table-column prop="couponInformationName" label="优惠券"></el-table-column>
          <el-table-column prop="couponCount" label="数量">
            <template #default="scope">
              <el-form-item :prop="'tableVip.' + scope.$index + '.couponCount'" label-width="0px">
                <div><el-input type="number" clearable v-model="scope.row.couponCount" placeholder="请输入" @change="(val) => { validatePositiveInteger(val, scope.$index); }"></el-input></div>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
        <div class="paginationBox">
          <el-pagination
            @current-change="handleCurrentChangeVip" 
            :current-page="optionVip.page"
            :page-size="5" prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
            next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;" 
            background 
            layout="total, prev, pager, next, jumper"
            :total="reDataForm.totalvip">
          </el-pagination>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetFormVip()">取 消</el-button>
          <el-button type="primary" @click="submitFormVip()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 二维码 -->
    <el-dialog v-model="codeDialogVisible" title="签到码" width="500px">
      <div>
        <QrCode :url="codeBindUrl"></QrCode>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="codeDialogVisible = false">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { nextTick, reactive, ref } from "vue";
import {
  getBannerManagementList,
  delBannerManagement,
  setStatusAsync,
  getCityTimeList,
  addBannerManagement,
  addBannerVipCoupon,
  getBannerManagementById,
  getVipCouponById,
  getVipCouponByIdK,
  delVipCoupon,
  updateBannerManagement,
  updateVipCoupon,
  getvipmanage,
  getCouponsRule,
  updateVipCouponCount,
  deleteConsumeRule, // 删除规则
  deleteRewardConfig // 删除规则下的配置
} from "../http/bannermanagement";
import { getvip, getCouponsList } from "../http/api";
import { putToOSS, getFileNameUUID, getFileExtensions } from "@/http/oss";
import { ElMessage } from "element-plus";
import QuillEditor from "../components/QuillEditor";
import QrCode from "../components/qrcode";
import { FullTimeFormat } from "../utils/common";
import { getCityList, updateEvalContent, getEvalContent } from "../http/api";
import { useRouter } from "vue-router";
export default {
  components: { QuillEditor, QrCode },
  name: "BannerManagement",
  setup() {
    const quillEditorRef = ref(null);
    const ruleFormRef = ref(null); //表单不能用this.$,取一个重名的
    const ruleFormRefMessage = ref(null); //表单不能用this.$,取一个重名的
    //跳转到分类设置页面
    const router = useRouter();
    //声明数据table表格
    let data = reactive({
      couponsList: [],
      table: [],
      cityListData: [],
      pages: 0,
      total: 0,
      bannerPositionListData: [
        //广告位置（1：开屏广告，2：三屏广告A，3：三屏广告B，4：三屏广告C）
        { id: 1, name: "开屏广告" },
        { id: 2, name: "四屏广告" },
        /*{id:3,name:'三屏广告B'},
          {id:4,name:'三屏广告C'}*/
      ],
      bannerTypeListData: [
        //广告类型（1：领券，2：报名，3：推荐好友）
        { id: 1, name: "领券" },
        { id: 2, name: "报名" },
        { id: 3, name: "推荐好友" },
        { id: 4, name: "门店预约" },
      ],
      statusListData: [
        //活动状态（1：未开始，2：进行中，3：已结束）
        { id: 1, name: "未开始" },
        { id: 2, name: "进行中" },
        { id: 3, name: "已结束" },
      ],
      optionsCity: [],
      VipListData: []  // 客户等级列表
    });
    let reData = reactive({
      bannerPositionListData: [
        //广告位置（1：开屏广告，2：三屏广告A，3：三屏广告B，4：三屏广告C）
        { id: 1, name: "开屏广告" },
        { id: 2, name: "四屏广告" },
        /*{id:3,name:'三屏广告B'},
          {id:4,name:'三屏广告C'}*/
      ],
      vipManageList: [],
      //自动启用时间是否禁用
      timeFlag: false,
      pickerOptions: [],
      dialogVisible: false,
      dialogVisibleKp: false,
      dialogVisibleDetail: false,
      vipManageFlag: true,
      isIndeterminate: false,
      dialogVisibleYqr: false, // 邀请人规则
      dialogVisibleByqr: false, // 被邀请人规则
      applicationTasks: [
        { id: "1", name: "姓名" },
        { id: "2", name: "电话" },
        { id: "3", name: "城市" },
        { id: "4", name: "地址" },
      ],
      checkAll: false,
      radioOne: null,
      radioTwo: null,
      couponListData: [],
    });
    let reDataForm = reactive({
      dialogFormVisibleVip: false,
      tableVip: [],
      totalvip: 0,
    });
    //声明查询条件
    let option = reactive({
      bannerPosition: null,
      bannerType: null,
      status: null,
      page: 1,
      pageSize: 10,
    });

    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };
    //图片回显
    let handleImgDelete = () => {
      ruleForm.img = false;
      ruleForm.bannerImgURL = null;
      ruleForm.upload = false;
    };
    //开屏广告图片回显
    let handleImgDeleteKp = () => {
      ruleForm.imgKp = false;
      ruleForm.bannerImgURLKp = null;
      ruleForm.uploadKp = false;
    };
    //广告详情图片回显
    let handleImgDeleteDetail = () => {
      ruleForm.imgDetail = false;
      ruleForm.bannerDetails = null;
      ruleForm.uploadDetail = false;
    };

    // ============================= 邀请好友功能 start =============================
    let value = ref('')
    // 被邀请人注册
    let activeName = ref('firstRule')
    let activeNameYqr = ref('yqrFirst')
    let tableLoading = ref(false);
    function getVipListDataFun(obj) {
      getvip(obj).then(res => {
        data.VipListData = res
      })
    }
    // 查看详情
    let lookDetail = ref(null)
    getVipListDataFun({})
    // 添加 注册/消费 邀请人/被邀请人 奖励
    function addInviterAward(item, type) {
      console.log('type', type);
      let obj = {
        inviteRewardConfigId: "",
        memberType: null,
        rewardType: null, // 赠送类型 1积分 2优惠券
        accumulatePoints: null, // 积分数量
        couponInformation: null, // 优惠券名称
        couponNumber: null // 优惠券数量
      }
      if (type == 'zcYqrAward') { // 被邀请人注册 邀请人奖励
        obj.memberType = 1
        ruleForm.registerRule.inviteRewards.push(obj)
      } else if(type == 'zcByqrAward') {
        obj.memberType = 2
        ruleForm.registerRule.invitedRewards.push(obj)
      } else if(type == 'xfYqrAward') {
        obj.memberType = 1
        item.inviteRewards.push(obj)
      } else if(type == 'xfByqrAward') {
        obj.memberType = 2
        item.invitedRewards.push(obj)
      } 
    }
    // 删除奖励
    function deleteReward(obj, arr, index) {
      deleteRewardConfig(obj).then(res => {
        if (res.code == 200) {
          ElMessage.success({
            message: res.message,
            duration: 2000
          });
          arr.splice(index, 1)
        } else {
          ElMessage.error({
            message: res.message,
            duration: 2000
          });
        }
      })
    }
    // 删除 注册/消费 邀请人/被邀请人 奖励
    function delInviterAward(item, index, type) {
      if (type == 'zcYqrAward') { // 被邀请人注册 邀请人奖励
        if (item.inviteRewardConfigId) {
          let obj = {
            inviteRewardConfigId: item.inviteRewardConfigId
          }
          deleteReward(obj, ruleForm.registerRule.inviteRewards, index)
        } else ruleForm.registerRule.inviteRewards.splice(index, 1)
      } else if(type == 'zcByqrAward') {
        if (item.inviteRewardConfigId) {
          let obj = {
            inviteRewardConfigId: item.inviteRewardConfigId
          }
          deleteReward(obj, ruleForm.registerRule.invitedRewards, index)
        } else ruleForm.registerRule.invitedRewards.splice(index, 1)
      } else if(type == 'xfYqrAward') {
        // 删除 规则奖励
        if(item.inviteRewards && item.inviteRewards[index].inviteRewardConfigId) {
          deleteRewardConfig({ inviteRewardConfigId: item.inviteRewards[index].inviteRewardConfigId }).then(res => {
            if (res.code == 200) {
              ElMessage.success({
                message: res.message,
                duration: 2000
              });
              item.inviteRewards.splice(index, 1)
            } else {
              ElMessage.error({
                message: res.message,
                duration: 2000
              });
            }
          })
        } else item.inviteRewards.splice(index, 1)
      } else if(type == 'xfByqrAward') {
        // 删除 规则奖励
        if(item.invitedRewards && item.invitedRewards[index].inviteRewardConfigId) {
          deleteRewardConfig({ inviteRewardConfigId: item.invitedRewards[index].inviteRewardConfigId }).then(res => {
            if (res.code == 200) {
              ElMessage.success({
                message: res.message,
                duration: 2000
              });
              item.invitedRewards.splice(index, 1)
            } else {
              ElMessage.error({
                message: res.message,
                duration: 2000
              });
            }
          })
        } else item.invitedRewards.splice(index, 1)
      }
    }
    // 添加消费规则
    function addConsumptionRule() {
      let obj = {
        inviteRuleConfigId: '',
        amountStart: null,
        amountEnd: null,
        activeName: `firstRule${ruleForm.consumeRules.length}`,
        inviteRewards: [],
        invitedRewards: []
      }
      ruleForm.consumeRules.push(obj)
    }
    // 删除业绩规则
    function delConsumptionRule(item, index) {
      console.log(item);
      if(item.inviteRuleConfigId) {
        deleteConsumeRule({ inviteRuleConfigId: item.inviteRuleConfigId }).then(res => {
          if (res.code == 200) {
            ElMessage.success({
              message: res.message,
              duration: 2000
            });
            ruleForm.consumeRules.splice(index, 1)
          } else {
            ElMessage.error({
              message: res.message,
              duration: 2000
            });
          }
        })
      } else ruleForm.consumeRules.splice(index, 1)
    }
    // 判断最大金额
    function changeAmountMax(item) {
      if(!item.amountEnd || item.amountStart) return
      if(item.amountEnd < item.amountStart) {
        item.amountEnd = ''
        ElMessage.warning({
          message: '最大金额不能小于最小金额',
          duration: 2000
        });
      }
    }
    function judge(start, end) {
      let cur = new Date()
      start = new Date(start.replace('T', ' '))
      end = new Date(end.replace('T', ' '))
      if(cur >= start && cur <= end) {
        return true
      } else return false
    }
    // ============================= 邀请好友功能 end =============================

    //获取项目列表方法
    let getBannerManagementListFun = async (obj) => {
      tableLoading.value = true
      if (option.bannerPosition == "") {
        option.bannerPosition = null;
        obj.bannerPosition = null;
      }
      if (option.bannerType == "") {
        option.bannerType = null;
        obj.bannerType = null;
      }
      if (option.status == "") {
        option.status = null;
        obj.status = null;
      }
      let arr = await getBannerManagementList(obj);
      tableLoading.value = false
      /*arr.items.filter(item => { item.isDefault = item.isDefault == true ? '是' : '否' })*/
      //格式化日期
      arr.items.filter((item) => {
        item.creationTime = FullTimeFormat(item.creationTime);
      });
      data.total = arr.totalCount;
      data.table = arr.items;
    };

    //初始化时，获取初始化数据
    getBannerManagementListFun(option);

    let codeDialogVisible = ref(false)
    let codeBindUrl = ref('')
    // 显示签到码
    let showCode = (item) => {
      codeBindUrl.value = `https://api.skin79.com.cn/apply?id=${item.id}`
      codeDialogVisible.value = true
    }
    // 查看老带新
    let oldWithNewFun = (item) => {
      console.log('老带新', item);
      router.push({
        name: "OldWithNew",
        query: { bannerManagementId: item.id },
      });
      
    }
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getBannerManagementListFun(option);
    };
    let handleCurrentChangeVip = (index) => {
      optionVip.page = index;
      getCounponName(optionVip);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      getBannerManagementListFun(option);
    };

    //表单参数
    let ruleForm = reactive({
      id: null,
      dialogFormVisible: false,
      addOrUpdate: 0, //0新增，1更新
      bannerType: null,
      bannerName: "",
      bannerCity: [],
      bannerPosition: null,
      bannerStartTime: null,
      taskTime: null,
      bannerImgURL: null,
      bannerImgURLKp: null,
      applicationTasks: [],
      peopleDayMaxCount: null,
      dayMaxCount: null,
      peopleMaxCount: null,
      coupon: [],
      accumulatePointsOne: null,
      inviteNumber: null,
      accumulatePointsTwo: null,
      couponInformationOne: [],
      couponInformationTwo: [],
      fileList: [],
      fileLists: [],
      transferImgList: [],
      transferImageDiv: false,
      bannerDetails: null,
      img: true,
      imgKp: true,
      imgDetail: true,
      upload: false,
      uploadKp: false,
      uploadDetail: false,
      fileListKp: [],
      fileListDetail: [],
      status: null,
      shareImage: "",
      transferImg: "",
      transferDescribe: "",
      // eventRules: '', // 活动规则
      participants: [], // 邀请人等级
      inviteImageUrlFileList: [], // 邀请人规则图片
      uploadYqrRule: false,
      imgDetailYqr: true,
      inviteImageUrl: '', // 邀请人规则图片
      invitedImageUrlFileList: [], // 被邀请人规则图片
      uploadByqrRule: false,
      imgDetailByqr: true,
      invitedImageUrl: '', // 被邀请人规则图片
      maxInviteNumber: null, // 最多邀请人数
      minInviteNumber: null, // 最少邀请人数
      registerRule: { // 被邀请人注册
        inviteRuleConfigId: '',
        amountStart: null,
        amountEnd: null,
        inviteRewards: [], // 邀请人奖励
        invitedRewards: [], // 被邀请人奖励
      },
      consumeRules: [], // 被邀请人消费

      activityDescription: '', // 活动描述
      venue: '', // 活动地点
      sponsorPhoneNum: '', // 主办方电话
      couponIds: [], // 优惠券(目前需求是单条,扩展：多条可以逗号拼
      applyTime: null // 举办时间
    });
    let ruleFormMessage = reactive({
      id: null,
      dialogFormVisibleMessage: false,
      evalContent: null,
    });
    let getCouponsListClick = async () => {
      if(!ruleForm.taskTime) return
      let params = {
        taskStartDate: ruleForm.taskTime[0],
        taskEndDate: ruleForm.taskTime[1],
        type: null // 优惠券领取方式
      };
      data.couponsList = await getCouponsList(params);
    };
    let submitFormVip = async () => {
      await updateVipCouponCount({
        vipCount: reDataForm.tableVip,
      });
      resetFormVip();
    };
    // 拆分时间
    let getFormattedTime = (time, type) => {
      const now = new Date(time);
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
    
      // 如果你只需要"HH:mm"格式
      const timeOnly = `${hours}:${minutes}`;
    
      // 如果需要完整的日期和时间格式
      const dateTime = `${year}-${month}-${day}`;

      let timeReturn
      if(type == 'day') {
        timeReturn = dateTime
      } else if(type == 'minutes') {
        timeReturn = timeOnly
      }
      return timeReturn;
    };
 
    //提交表单
    let submitForm = async (formName) => {
      // 报名活动
      if (ruleForm.bannerType == 2) {
        let content = quillEditorRef.value.entity.content;
        ruleForm.activityDescription = content;
      }
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          return false;
        }
      });
      if (con) return

      let bannerMagId = null;
      //修改
      if (ruleForm.addOrUpdate == 1) {
        bannerMagId = ruleForm.id;
      }
      //启用状态进行校验
      if (ruleForm.status == "1" && ruleForm.bannerType != 3) {
        //提交验证（//规则设置判断城市和时间是否重复，如果重复需要有提示信息（select 城市和时间，））广告唯一性
        let bannerCity = ruleForm.bannerCity.join("，");
        let cityTime = {
          bannerCity: bannerCity,
          bannerType: ruleForm.bannerType,
          taskStartTime: new Date(ruleForm.taskTime[0]),
          taskEndTime: new Date(ruleForm.taskTime[1]),
          id: bannerMagId,
        };
        let cityTimeResult = await getCityTimeList(cityTime);
        if (cityTimeResult.code != "200") {
          ElMessage.error({
            message: cityTimeResult.message,
            duration: 2000,
          });
          return false;
        }
      }

      //活动状态
      let status = null;
      if (Date.now() < new Date(ruleForm.taskTime[0])) {
        status = 1;
      } else if (Date.now() > new Date(ruleForm.taskTime[1])) {
        status = 3;
      } else {
        status = 2;
      }
      if (ruleForm.bannerStartTime == null || ruleForm.bannerStartTime == "") {
        ruleForm.bannerStartTime = ruleForm.taskTime[0];
      }
      if (ruleForm.peopleDayMaxCount == null) {
        ruleForm.peopleDayMaxCount = 0;
      } else if (ruleForm.bannerType != 1) {
        ruleForm.peopleDayMaxCount = 0;
      }
      if (ruleForm.dayMaxCount == null) {
        ruleForm.dayMaxCount = 0;
      } else if (ruleForm.bannerType != 1) {
        ruleForm.dayMaxCount = 0;
      }
      if (ruleForm.peopleMaxCount == null) {
        ruleForm.peopleMaxCount = 0;
      } else if (ruleForm.bannerType != 1) {
        ruleForm.peopleMaxCount = 0;
      }
      if (ruleForm.accumulatePointsOne == null) {
        ruleForm.accumulatePointsOne = 0;
      } else if (ruleForm.bannerType != 3) {
        ruleForm.accumulatePointsOne = 0;
      }
      if (ruleForm.inviteNumber == null) {
        ruleForm.inviteNumber = 0;
      } else if (ruleForm.bannerType != 3) {
        ruleForm.inviteNumber = 0;
      }
      if (ruleForm.accumulatePointsTwo == null) {
        ruleForm.accumulatePointsTwo = 0;
      } else if (ruleForm.bannerType != 3) {
        ruleForm.accumulatePointsTwo = 0;
      }
      let applicationTasks = null;
      let couponInformation = null;
      let consumptionCoupon = null;
      if (ruleForm.bannerType == 2 && ruleForm.applicationTasks != null && ruleForm.applicationTasks.length > 0) {
        applicationTasks = ruleForm.applicationTasks.join(",");
      }
      if (ruleForm.bannerType == 3) {
        if ( ruleForm.couponInformationOne != null && ruleForm.couponInformationOne.length > 0 && reData.radioOne == "2") {
          couponInformation = ruleForm.couponInformationOne.join(",");
        }
        if ( ruleForm.couponInformationTwo != null && ruleForm.couponInformationTwo.length > 0 && reData.radioTwo == "2") {
          consumptionCoupon = ruleForm.couponInformationTwo.join(",");
        }
      }
      if (ruleForm.bannerType != 1) {
        //删除领券
        await delVipCoupon({ bannerManagementId: ruleForm.id });
      }
      if (ruleForm.addOrUpdate == 1) {
        //更新
        let data = {
          id: ruleForm.id,
          bannerName: ruleForm.bannerName,
          bannerPosition: ruleForm.bannerPosition,
          bannerCity: ruleForm.bannerCity.join("，"),
          bannerType: ruleForm.bannerType,
          status: Number(ruleForm.status),
          taskStartTime: ruleForm.taskTime[0],
          taskEndTime: ruleForm.taskTime[1],
          bannerStartTime: new Date(ruleForm.bannerStartTime),
          bannerImgURL: ruleForm.bannerImgURL,
          bannerImgURLKp: ruleForm.bannerImgURLKp,
          peopleDayMaxCount: ruleForm.peopleDayMaxCount,
          dayMaxCount: ruleForm.dayMaxCount,
          peopleMaxCount: ruleForm.peopleMaxCount,
          applicationTaskItem: applicationTasks,
          accumulatePoints: ruleForm.accumulatePointsOne,
          inviteNumber: ruleForm.inviteNumber,
          couponInformation: couponInformation,
          consumptionPoints: ruleForm.accumulatePointsTwo,
          consumptionCoupon: consumptionCoupon,
          bannerDetails: ruleForm.bannerDetails,
          shareTitle: ruleForm.shareTitle,
          shareImage: ruleForm.shareImage,
          transferImg: ruleForm.transferImg,
          shareDescription: ruleForm.shareDescription,
          transferDescribe: ruleForm.transferDescribe,
          // 邀请好友  赋值
          // eventRules: ruleForm.eventRules,
          participants: ruleForm.participants.join(","), // 邀请人等级
          inviteImageUrl: ruleForm.inviteImageUrl, // 邀请人规则图片
          invitedImageUrl: ruleForm.invitedImageUrl, // 邀请人规则图片
          maxInviteNumber: +ruleForm.maxInviteNumber, // 最多邀请人数
          minInviteNumber: +ruleForm.minInviteNumber, // 最少邀请人数
          registerRule: ruleForm.registerRule, // 被邀请人注册
          consumeRules: ruleForm.consumeRules, // 被邀请人消费
        };
        // 报名活动
        if(ruleForm.bannerType == 2) {
          let obj = {
            activityDescription: ruleForm.activityDescription,
            venue: ruleForm.venue,
            sponsorPhoneNum: ruleForm.sponsorPhoneNum,
            couponIds: ruleForm.couponIds.join(','),
            eventStartDate: ruleForm.applyTime[0],
            eventEndDate: ruleForm.applyTime[1]
          }
          Object.assign(data, obj)
        }
        let res = await updateBannerManagement(data);
        if(res.code != 200) return ElMessage.error({
          message: res.message,
          duration: 2000
        });
        if (ruleForm.id != null || ruleForm.id != "") {
          if (ruleForm.bannerType == 1) {
            //领券类型，并且券信息不为空时进行插入
            let vipCouponMap = {};
            if (reData.vipManageList != null && reData.vipManageList.length > 0) {
              for (let i = 0; i < reData.vipManageList.length; i++) {
                let vipId = reData.vipManageList[i].id;
                if ( ruleForm.coupon[i] != null && ruleForm.coupon[i].length > 0) {
                  vipCouponMap[vipId] = ruleForm.coupon[i];
                }
              }
            }
            let typeData = {
              bannerManagementId: ruleForm.id,
              vipCouponMap: vipCouponMap,
            };
            await updateVipCoupon(typeData);
          }
        }
      } else {
        //新增
        let data = {
          bannerName: ruleForm.bannerName,
          bannerPosition: ruleForm.bannerPosition,
          bannerCity: ruleForm.bannerCity.join("，"),
          bannerType: ruleForm.bannerType,
          status: Number(ruleForm.status),
          taskStartTime: ruleForm.taskTime[0],
          taskEndTime: ruleForm.taskTime[1],
          bannerStartTime: new Date(ruleForm.bannerStartTime),
          bannerImgURL: ruleForm.bannerImgURL,
          bannerImgURLKp: ruleForm.bannerImgURLKp,
          peopleDayMaxCount: ruleForm.peopleDayMaxCount,
          dayMaxCount: ruleForm.dayMaxCount,
          peopleMaxCount: ruleForm.peopleMaxCount,
          applicationTaskItem: applicationTasks,
          accumulatePoints: ruleForm.accumulatePointsOne,
          inviteNumber: ruleForm.inviteNumber,
          couponInformation: couponInformation,
          consumptionPoints: ruleForm.accumulatePointsTwo,
          consumptionCoupon: consumptionCoupon,
          bannerDetails: ruleForm.bannerDetails,
          shareTitle: ruleForm.shareTitle,
          shareImage: ruleForm.shareImage,
          transferImg: ruleForm.transferImg,
          shareDescription: ruleForm.shareDescription,
          transferDescribe: ruleForm.transferDescribe,
          // 邀请好友  赋值
          // eventRules: ruleForm.eventRules,
          participants: ruleForm.participants.join(","), // 邀请人等级
          inviteImageUrl: ruleForm.inviteImageUrl, // 邀请人规则图片
          invitedImageUrl: ruleForm.invitedImageUrl, // 邀请人规则图片
          maxInviteNumber: +ruleForm.maxInviteNumber, // 最多邀请人数
          minInviteNumber: +ruleForm.minInviteNumber, // 最少邀请人数
          registerRule: ruleForm.registerRule, // 被邀请人注册
          consumeRules: ruleForm.consumeRules, // 被邀请人消费
        };
        // 报名活动
        if(ruleForm.bannerType == 2) {
          let obj = {
            activityDescription: ruleForm.activityDescription,
            venue: ruleForm.venue,
            sponsorPhoneNum: ruleForm.sponsorPhoneNum,
            couponIds: ruleForm.couponIds.join(','),
            eventStartDate: ruleForm.applyTime[0],
            eventEndDate: ruleForm.applyTime[1]
          }
          Object.assign(data, obj)
        }
        let bannerId = await addBannerManagement(data);
        if(bannerId.code != 200) return ElMessage.error({
          message: bannerId.message,
          duration: 2000
        });
        if (bannerId.data != null && (bannerId.data.id != null || bannerId.data.id != "")) {
          if (ruleForm.bannerType == 1) {
            //领券类型，并且券信息不为空时进行插入
            let vipCouponMap = {};
            if (
              reData.vipManageList != null &&
              reData.vipManageList.length > 0
            ) {
              for (let i = 0; i < reData.vipManageList.length; i++) {
                let vipId = reData.vipManageList[i].id;
                if (
                  ruleForm.coupon[i] != null &&
                  ruleForm.coupon[i].length > 0
                ) {
                  vipCouponMap[vipId] = ruleForm.coupon[i];
                }
              }
            }
            if (vipCouponMap != null && vipCouponMap != {}) {
              let typeData = {
                bannerManagementId: bannerId.data.id,
                vipCouponMap: vipCouponMap,
              };
              await addBannerVipCoupon(typeData);
            }
          }
        } else {
          ElMessage.error({
            message: "提交失败！",
            duration: 2000,
          });
        }
      }
      getBannerManagementListFun(option);
      resetForm();
    };
    let submitFormMessage = async (formName) => {
      await updateEvalContent({
        evalContent: ruleFormMessage.evalContent,
        evalType: 12,
      });
      resetFormMessage();
    };
    //获取城市列表方法
    let getCityListDataFun = async (obj) => {
      let cityList = await getCityList(obj);
      data.cityListData = cityList;
      data.cityListData.push({ id: "0", name: "全国" });
    };
    getCityListDataFun({});
    //点击新增按钮
    let AddClick = async () => {
      lookDetail.value = 'add';
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleForm.addOrUpdate = 0;
      ruleForm.taskTime = null;
      ruleForm.upload = false;
      ruleForm.imageDivs = false;
      ruleForm.transferImageDiv = false;
    };
    //配置提示信息
    let setMessage = async () => {
      ruleFormMessage.dialogFormVisibleMessage = true;
      //数据回显
      let message = await getEvalContent({ evalType: 12 });
      if (message != null && message.length > 0) {
        ruleFormMessage.evalContent = message[0].evalContent;
      }
    };
    //修改的表单验证
    let rules = reactive({
      activityDescription: [{ required: true, message: "请填写活动描述" }],
      venue: [{ required: true, message: "请填写活动地点" }],
      couponIds: [{ required: true, message: "请选择优惠券" }],
      applyTime: [{ required: true, message: "请选择举办时间" }],


      bannerName: [{ required: true, message: "请填写广告名称" }],
      bannerCity: [{ required: true, message: "请选择适用城市" }],
      shareTitle: [{ required: true, message: "请填写分享标题" }],
      bannerType: [{ required: true, message: "请选择广告类型" }],
      bannerPosition: [{ required: true, message: "请选择广告位置" }],
      taskTime: [{ required: true, message: "请选择活动时间" }],
      bannerImgURL: [{ required: true, message: "请上传广告图" }],
      bannerImgURLKp: [{ required: true, message: "请上传背景图" }],
      status: [{ required: true, message: "请选择是否启用" }],
      inviteImageUrl: [{ required: true, message: "请上传邀请人规则图片" }], // 邀请人规则图片
      invitedImageUrl: [{ required: true, message: "请上传被邀请人规则图片" }], // 被邀请人规则图片
      participants: [{ required: true, message: "请选择邀请人等级" }], // 邀请人等级
      maxInviteNumber: [{ required: true, message: "请输入最多邀请人数" }], // 最多邀请人数
      shareImage: [{ required: true, message: "请上传分享图片" }],
      transferImg: [{ required: true, message: "请上传转发图片" }],
      transferDescribe: [
        { required: true, message: '转发说明不能为空', trigger: 'blur' },
        { min: 5, max: 25, message: '转发说明文字限制5-25个字', trigger: 'blur' }
      ],
      // eventRules: [{ required: true, message: "请填写活动规则" }]
      consumeRules: { // 消费规则校验
        type: "array",
        trigger: ["blur", "change"],
        amountStart: {  // 消费金额  start
          validator: (rule, value, callback) => {
            const index = rule.field.split(".")[1];
            const detail = ruleForm.consumeRules[index];
            if (detail && !detail.amountStart) {
              callback(new Error("消费金额不能为空"));
            }
            callback();
          },
          trigger: "blur"
        },
        amountEnd: {  // 消费金额  end
          validator: (rule, value, callback) => {
            const index = rule.field.split(".")[1];
            const detail = ruleForm.consumeRules[index];
            if (detail && !detail.amountEnd) {
              callback(new Error("消费金额不能为空"));
            }
            callback();
          },
          trigger: "blur"
        },
        inviteRewards: { // 规则 - 邀请人奖励
          type: "array",
          trigger: ["blur", "change"],
          rewardType: {
            required: true,
            message: "赠送类型不能为空",
            trigger: "change",
          },
          accumulatePoints: { // 积分数量
            validator: (rule, value, callback) => {
              const index = rule.field.split(".")[1];
              const idx = rule.field.split(".")[3];
              const detail = ruleForm.consumeRules[index].inviteRewards[idx];
              if (detail && (detail.rewardType == 1 && !detail.accumulatePoints)) {
                callback(new Error("积分数量不能为空"));
              }
              callback();
            },
            trigger: "blur"
          },
          couponInformation: { // 优惠券名称
            validator: (rule, value, callback) => {
              const index = rule.field.split(".")[1];
              const idx = rule.field.split(".")[3];
              const detail = ruleForm.consumeRules[index].inviteRewards[idx];
              if (detail && (detail.rewardType == 2 && !detail.couponInformation)) {
                callback(new Error("优惠券名称不能为空"));
              }
              callback();
            },
            trigger: "change"
          },
          couponNumber: { // 优惠券数量
            validator: (rule, value, callback) => {
              const index = rule.field.split(".")[1];
              const idx = rule.field.split(".")[3];
              const detail = ruleForm.consumeRules[index].inviteRewards[idx];
              if (detail && (detail.rewardType == 2 && !detail.couponNumber)) {
                callback(new Error("优惠券数量不能为空"));
              }
              callback();
            },
            trigger: "blur"
          }
        },
        invitedRewards: { // 规则 - 被邀请人奖励
          type: "array",
          trigger: ["blur", "change"],
          rewardType: {
            required: true,
            message: "赠送类型不能为空",
            trigger: "change",
          },
          accumulatePoints: { // 积分数量
            validator: (rule, value, callback) => {
              const index = rule.field.split(".")[1];
              const idx = rule.field.split(".")[3];
              const detail = ruleForm.consumeRules[index].invitedRewards[idx];
              if (detail && (detail.rewardType == 1 && !detail.accumulatePoints)) {
                callback(new Error("积分数量不能为空"));
              }
              callback();
            },
            trigger: "blur"
          },
          couponInformation: { // 优惠券名称
            validator: (rule, value, callback) => {
              const index = rule.field.split(".")[1];
              const idx = rule.field.split(".")[3];
              const detail = ruleForm.consumeRules[index].invitedRewards[idx];
              if (detail && (detail.rewardType == 2 && !detail.couponInformation)) {
                callback(new Error("优惠券名称不能为空"));
              }
              callback();
            },
            trigger: "change"
          },
          couponNumber: { // 优惠券数量
            validator: (rule, value, callback) => {
              const index = rule.field.split(".")[1];
              const idx = rule.field.split(".")[3];
              const detail = ruleForm.consumeRules[index].invitedRewards[idx];
              if (detail && (detail.rewardType == 2 && !detail.couponNumber)) {
                callback(new Error("优惠券数量不能为空"));
              }
              callback();
            },
            trigger: "blur"
          }
        }

      },
      inviteRewards: { // 注册  邀请人奖励
        type: "array",
        trigger: ["blur", "change"],
        rewardType: {  // 赠送类型
          validator: (rule, value, callback) => {
            const index = rule.field.split(".")[2];
            const detail = ruleForm.registerRule.inviteRewards[index];
            if (detail && !detail.rewardType) {
              callback(new Error("赠送类型不能为空"));
            }
            callback();
          },
          trigger: "change"
        },
        accumulatePoints: { // 积分数量
          validator: (rule, value, callback) => {
            const index = rule.field.split(".")[2];
            const detail = ruleForm.registerRule.inviteRewards[index];
            if (detail && (detail.rewardType == 1 && !detail.accumulatePoints)) {
              callback(new Error("积分数量不能为空"));
            }
            callback();
          },
          trigger: "blur"
        },
        couponInformation: { // 优惠券名称
          validator: (rule, value, callback) => {
            const index = rule.field.split(".")[2];
            const detail = ruleForm.registerRule.inviteRewards[index];
            if (detail && (detail.rewardType == 2 && !detail.couponInformation)) {
              callback(new Error("优惠券名称不能为空"));
            }
            callback();
          },
          trigger: "change"
        },
        couponNumber: { // 优惠券数量
          validator: (rule, value, callback) => {
            const index = rule.field.split(".")[2];
            const detail = ruleForm.registerRule.inviteRewards[index];
            if (detail && (detail.rewardType == 2 && !detail.couponNumber)) {
              callback(new Error("优惠券数量不能为空"));
            }
            callback();
          },
          trigger: "blur"
        }
      },
      invitedRewards: { // 注册  被邀请人奖励
        type: "array",
        trigger: ["blur", "change"],
        rewardType: {  // 赠送类型
          validator: (rule, value, callback) => {
            const index = rule.field.split(".")[2];
            const detail = ruleForm.registerRule.invitedRewards[index];
            if (detail && !detail.rewardType) {
              callback(new Error("赠送类型不能为空"));
            }
            callback();
          },
          trigger: "change"
        },
        accumulatePoints: { // 积分数量
          validator: (rule, value, callback) => {
            const index = rule.field.split(".")[2];
            const detail = ruleForm.registerRule.invitedRewards[index];
            if (detail && (detail.rewardType == 1 && !detail.accumulatePoints)) {
              callback(new Error("积分数量不能为空"));
            }
            callback();
          },
          trigger: "blur"
        },
        couponInformation: { // 优惠券名称
          validator: (rule, value, callback) => {
            const index = rule.field.split(".")[2];
            const detail = ruleForm.registerRule.invitedRewards[index];
            if (detail && (detail.rewardType == 2 && !detail.couponInformation)) {
              callback(new Error("优惠券名称不能为空"));
            }
            callback();
          },
          trigger: "change"
        },
        couponNumber: { // 优惠券数量
          validator: (rule, value, callback) => {
            const index = rule.field.split(".")[2];
            const detail = ruleForm.registerRule.invitedRewards[index];
            if (detail && (detail.rewardType == 2 && !detail.couponNumber)) {
              callback(new Error("优惠券数量不能为空"));
            }
            callback();
          },
          trigger: "blur"
        }
      }
    });
    let validatePositiveInteger = (value, index) => {
      const reg = /^\d+$/; // 正整数的正则表达式
      if (!reg.test(value)) {
        reDataForm.tableVip[index].couponCount = 0;
        ElMessage.error({
          message: "请输入非负整数！",
          duration: 2000,
        });
      }
    };
    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate = 1;
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleForm.upload = true;
      ruleForm.img = true;
      ruleForm.imgKp = true;
      ruleForm.imgDetail = true;
      ruleForm.imgDetailYqr = true;
      ruleForm.imgDetailByqr = true;
      
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let bannerManagement = await getBannerManagementById({ id: obj.id });
      reData.timeFlag = false;
      reData.dialogVisible = false;
      reData.dialogVisibleKp = false;
      reData.dialogVisibleDetail = false;
      ruleForm.bannerType = bannerManagement.bannerType;
      ruleForm.bannerName = bannerManagement.bannerName;
      ruleForm.bannerCity = bannerManagement.bannerCity.split("，");
      ruleForm.bannerPosition = bannerManagement.bannerPosition;
      ruleForm.taskTime = [];
      ruleForm.taskTime.push(bannerManagement.taskStartTime);
      ruleForm.taskTime.push(bannerManagement.taskEndTime);
      reData.pickerOptions = ruleForm.taskTime;
      ruleForm.bannerStartTime = bannerManagement.bannerStartTime;
      ruleForm.status = String(bannerManagement.status);
      ruleForm.bannerImgURL = bannerManagement.bannerImgURL;
      ruleForm.bannerImgURLKp = bannerManagement.bannerImgURLKp;
      ruleForm.bannerDetails = bannerManagement.bannerDetails;
      ruleForm.shareTitle = bannerManagement.shareTitle;
      ruleForm.shareImage = bannerManagement.shareImage;
      ruleForm.transferImg = bannerManagement.transferImg;
      ruleForm.imageDivs = true;
      ruleForm.transferImageDiv = true;
      ruleForm.transferDescribe = bannerManagement.transferDescribe;
      ruleForm.shareDescription = bannerManagement.shareDescription;
      // 邀请好友  赋值
      // ruleForm.eventRules = bannerManagement.eventRules;
      ruleForm.inviteImageUrl = bannerManagement.inviteImageUrl; // 邀请人规则图片
      ruleForm.invitedImageUrl = bannerManagement.invitedImageUrl; // 邀请人规则图片
      ruleForm.participants = bannerManagement.participants.split(","); // 邀请人等级
      ruleForm.maxInviteNumber = +bannerManagement.maxInviteNumber; // 最多邀请人数
      ruleForm.minInviteNumber = +bannerManagement.minInviteNumber; // 最少邀请人数
      ruleForm.registerRule = bannerManagement.registerRule; // 被邀请人注册
      ruleForm.consumeRules = bannerManagement.consumeRules; // 被邀请人消费

      // 报名活动信息
      if (bannerManagement.bannerType == 2) {
        ruleForm.activityDescription = bannerManagement.activityDescription; // 活动描述
        nextTick(() => {
          quillEditorRef.value.$refs['quill-editor'].setHTML(bannerManagement.activityDescription);
        })
        ruleForm.venue = bannerManagement.venue; // 活动地点
        ruleForm.sponsorPhoneNum = bannerManagement.sponsorPhoneNum; // 主办方电话
        // ruleForm.couponIds = bannerManagement.couponIds.split(","); // 优惠券(目前需求是单条,扩展：多条可以逗号拼
        // let startTime = getFormattedTime(bannerManagement.eventStartDate.replace("T", " "), 'day')
        // let endTime = getFormattedTime(bannerManagement.eventEndDate.replace("T", " "), 'day')
        // ruleForm.applyTime = [startTime + ' ' + bannerManagement.eventDayStartTime+':00', endTime + ' ' + bannerManagement.eventDayEndTime+':00'] // 举办时间
        // console.log(ruleForm.applyTime);
        ruleForm.applyTime = [bannerManagement.eventStartDate.replace("T", " "), bannerManagement.eventEndDate.replace("T", " ")]

        
        if (bannerManagement.couponIds != null && bannerManagement.couponIds != "") {
          let couponsListStr = bannerManagement.couponIds.split(",");
          let arr = [];
          for (var i = 0; i < couponsListStr.length; i++) {
            arr.push(parseInt(couponsListStr[i]));
            if (arr.length > 0) {
              ruleForm.couponIds = arr;
            }
          }
        }
        await getCouponsListClick()
      }
      
      
      // tabs 默认值
      ruleForm.consumeRules?.forEach((val, index) => {
        val.activeName = `firstRule${index}`
      })
      if (ruleForm.bannerType != 1) {
        ruleForm.peopleDayMaxCount = null;
        ruleForm.dayMaxCount = null;
        ruleForm.peopleMaxCount = null;
        ruleForm.coupon = [];
      } else {
        ruleForm.peopleDayMaxCount = bannerManagement.peopleDayMaxCount;
        ruleForm.dayMaxCount = bannerManagement.dayMaxCount;
        ruleForm.peopleMaxCount = bannerManagement.peopleMaxCount;
        //getCouponsRuleListFun({get_type:10,start_date:"2023-01-31 00:00:00",end_date:"2024-01-31 00:00:00"});
        getCouponsRuleListFun({
          get_type: 10,
          start_date: ruleForm.taskTime[0].replace("T", " "),
          end_date: ruleForm.taskTime[1].replace("T", " "),
        });
      }
      if (ruleForm.bannerType == 3) {
        getCouponsRuleListFun({
          get_type: 10,
          start_date: ruleForm.taskTime[0].replace("T", " "),
          end_date: ruleForm.taskTime[1].replace("T", " "),
        });
      }
      if (bannerManagement.applicationTaskItem != null && bannerManagement.applicationTaskItem != "") {
        ruleForm.applicationTasks = bannerManagement.applicationTaskItem.split(",");
      } else {
        ruleForm.applicationTasks = [];
      }
      reData.isIndeterminate = false;
      if (ruleForm.applicationTasks != null && ruleForm.applicationTasks.length > 0) {
        if (ruleForm.applicationTasks.length == reData.applicationTasks.length) {
          reData.checkAll = true;
        }
      } else {
        reData.checkAll = false;
      }
      if (ruleForm.bannerType == 3) {
        if (bannerManagement.couponInformation != null && bannerManagement.couponInformation != "") {
          reData.radioOne = "2";
          ruleForm.couponInformationOne = bannerManagement.couponInformation.split(",").map(Number);
          ruleForm.accumulatePointsOne = null;
        } else {
          reData.radioOne = "1";
          ruleForm.accumulatePointsOne = bannerManagement.accumulatePoints;
          ruleForm.couponInformationOne = [];
        }
        if (bannerManagement.consumptionCoupon != null && bannerManagement.consumptionCoupon != "") {
          reData.radioTwo = "2";
          ruleForm.couponInformationTwo = bannerManagement.consumptionCoupon.split(",").map(Number);
          ruleForm.accumulatePointsTwo = null;
        } else {
          reData.radioTwo = "1";
          ruleForm.accumulatePointsTwo = bannerManagement.consumptionPoints;
          ruleForm.couponInformationTwo = [];
        }
        if (bannerManagement.inviteNumber != null && bannerManagement.inviteNumber != "") {
          ruleForm.inviteNumber = bannerManagement.inviteNumber;
        }
      } else {
        ruleForm.accumulatePointsOne = null;
        ruleForm.inviteNumber = null;
        ruleForm.accumulatePointsTwo = null;
        reData.radioOne = null;
        reData.radioTwo = null;
        ruleForm.couponInformationOne = [];
        ruleForm.accumulatePointsOne = null;
      }
    };
    let imageClose = () => {
      ruleForm.eventBg = "";
      ruleForm.shareImage = "";
      ruleForm.transferImg = "";
      ruleForm.imageDiv = false;
      ruleForm.imageDivs = false;
      ruleForm.transferImageDiv = false;
      ruleForm.imageDivmp3 = false;
    };
    //删除事件
    let deleteClick = async (obj) => {
      let data = { id: obj.id };
      let delResult = await delBannerManagement(data);
      getBannerManagementListFun(option);
      if (delResult) {
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      } else {
        ElMessage.error({
          message: "删除失败！",
          duration: 2000,
        });
      }
    };
    //广告位置获取焦点时进行校验，先填写广告类型再填写广告位置
    let positionFocus = () => {
      /*if(ruleForm.bannerType == null || ruleForm.bannerType == "") {
          ElMessage.error({
            message: "请先选择广告类型！",
            duration: 2000
          });
          reData.bannerPositionListData = [];
        }*/
    };
    //自动启用时间获取焦点时进行校验，先填写活动时间再填写自动启用时间
    let timeFocus = () => {
      if (ruleForm.taskTime == null) {
        ElMessage.error({
          message: "请先选择活动时间！",
          duration: 2000,
        });
        reData.timeFlag = true;
      } else {
        reData.timeFlag = false;
      }
    };
    //如果活动时间为空则清空自动启用时间
    let timeChange = (value) => {
      if (reData.timeFlag) {
        ruleForm.bannerStartTime = null;
      } else {
        if (value != null) {
          if ( new Date(value).getTime() < new Date(ruleForm.taskTime[0]).getTime() || new Date(value).getTime() > new Date(ruleForm.taskTime[1]).getTime()) {
            ruleForm.bannerStartTime = null;
            ElMessage.error({
              message: "自动启用时间不能在活动时间范围外！",
              duration: 2000,
            });
          }
        }
      }
    };
    // 举办时间校验
    let applyTimeChange = (value) => {
      if (value != null) {
        // if (new Date(value[0]).getTime() > Date.now()) {
        //   reData.pickerOptions = value;
        //   baPickerOptions();
        // } else {
        //   ruleForm.applyTime = [];
        //   ElMessage.error({
        //     message: "活动时间不能小于当前时间！",
        //     duration: 2000,
        //   });
        // }
        if (new Date(value[0]).getTime() == new Date(value[1]).getTime()) {
          ruleForm.applyTime = [];
          ElMessage.error({
            message: "活动时间的开始时间和结束时间不能相等！",
            duration: 2000,
          });
        }
      }
      if (ruleForm.applyTime != null && ruleForm.applyTime.length > 0) {
        getCouponsRuleListFun({
          get_type: 10,
          start_date: ruleForm.applyTime[0].replace("T", " "),
          end_date: ruleForm.applyTime[1].replace("T", " "),
        });
      }
    }
    //活动时间改变时，进行自动启用时间的范围限制
    let taskChange = (value) => {
      if (ruleForm.bannerType != 2) { // 报名活动不校验时间
        if (value != null) {
          ruleForm.bannerStartTime = null;
          if (new Date(value[0]).getTime() > Date.now()) {
            reData.pickerOptions = value;
            baPickerOptions();
          } else {
            ruleForm.taskTime = [];
            ElMessage.error({
              message: "活动时间不能小于当前时间！",
              duration: 2000,
            });
          }
          if (new Date(value[0]).getTime() == new Date(value[1]).getTime()) {
            ruleForm.taskTime = [];
            ElMessage.error({
              message: "活动时间的开始时间和结束时间不能相等！",
              duration: 2000,
            });
          }
        }
      }
      if (ruleForm.taskTime != null && ruleForm.taskTime.length > 0) {
        getCouponsRuleListFun({
          get_type: 10,
          start_date: ruleForm.taskTime[0].replace("T", " "),
          end_date: ruleForm.taskTime[1].replace("T", " "),
        });
        //getCouponsRuleListFun({get_type:10,start_date:"2023-01-31 00:00:00",end_date:"2024-01-31 00:00:00"});
      }
    };
    //广告类型值改变时时进行广告位置的赋值
    let positionChange = (value) => {
      /*if(value !=null && value != ""){
        if(value == 4){
            reData.bannerPositionListData=[
                {id:1,name:'开屏广告'}
                ];
            ruleForm.bannerPosition = null;
        }else{
            reData.bannerPositionListData=[
                {id:1,name:'开屏广告'},
                {id:2,name:'三屏广告A'},
                {id:3,name:'三屏广告B'},
                {id:4,name:'三屏广告C'}
            ];
        }}*/
    };
    //关闭活动,将活动状态变为已结束
    let closeStatus = async (obj) => {
      console.log('obj', obj);
      if (obj != null) {
        /*if(obj.status == 3) {
            ElMessage.error({
                message: "活动状态是已结束，不需要点击关闭活动按钮！",
                duration: 2000
            });
          } else {
            let result = await setStatusAsync({"id": obj.id});
            getBannerManagementListFun(option);
            if (result.code == "200") {
              ElMessage.success({
                  message: result.message,
                  duration: 2000
              });
            }else {
              ElMessage.error({
                message: result.message,
                duration: 2000
              });
            }
          }*/
        //将启用状态改为禁用状态（直接关闭）
        if (obj.status == "2" && obj.bannerType != 3) {
          //将禁用状态改为启用状态（需要判断时间冲突）
          //提交验证（//规则设置判断城市和时间是否重复，如果重复需要有提示信息（select 城市和时间，））广告唯一性
          let cityTime = {
            bannerCity: obj.cityIds,
            bannerType: obj.bannerType,
            taskStartTime: new Date(obj.taskStartTime),
            taskEndTime: new Date(obj.taskEndTime),
            id: obj.id,
          };
          let cityTimeResult = await getCityTimeList(cityTime);
          if (cityTimeResult.code != "200") {
            ElMessage.error({
              message: cityTimeResult.message,
              duration: 2000,
            });
            return false;
          }
        }
        //改变状态（禁用变启用。启用变禁用）
        let result = await setStatusAsync({ id: obj.id });
        getBannerManagementListFun(option);
        if (result.code == "200") {
          ElMessage.success({
            message: result.message,
            duration: 2000,
          });
        } else {
          ElMessage.error({
            message: result.message,
            duration: 2000,
          });
        }
      }
    };
    //定义自动启用时间的范围
    let baPickerOptions = (time) => {
      return (
        new Date(time).getTime() >
        new Date(reData.pickerOptions[1]).getTime() ||
        new Date(time).getTime() < new Date(reData.pickerOptions[0]).getTime()
      );
    };
    //定义活动开始时间的范围
    let taskTimeOptions = (time) => {
      return new Date(time).getTime() < Date.now() - 8.64e7;
    };
    // 图片上传超出限制
    // function handleExceed() {
    //   ElMessage.warning('最多上传一张图片')
    // }
    let beforeUpload = (file) => {
      // 限制上传类型
      const fileExtensions = getFileExtensions(file.name) === ".jpg" || getFileExtensions(file.name) === ".png" || getFileExtensions(file.name) === ".jpeg";
      //限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000,
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000,
        });
      }
      return fileExtensions && max2M;
    };
    // 转发
    let transferHandleUpload = (option) => {
      let objName = "transferImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        ruleForm.transferImg = res.url;
        ruleForm.transferImageDiv = true;
      });
    };
    let handleUploads = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = "GoodsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        ruleForm.shareImage = res.url;
        ruleForm.imageDivs = true;
      });
    };
    let handleUpload = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName ="BannerManagementsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        ruleForm.bannerImgURL = res.url;
      });
    };
    //上传开屏广告图
    let handleUploadKp = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = "BannerManagementsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
        // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
        putToOSS(`flieName/${objName}`, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        ruleForm.bannerImgURLKp = res.url;
      });
    };
    //上传广告详情图
    let handleUploadDetail = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = "BannerManagementsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        ruleForm.bannerDetails = res.url;
      });
    };
    //预览图片
    let handlePreview = () => {
      reData.dialogVisible = true;
    };
    let handlePreviews = (file) => {
      console.log(file);
    };
    let handleRemoves = (file, fileLists) => {
      console.log(file, fileLists);
    };
    //预览开屏广告图
    let handlePreviewKp = () => {
      reData.dialogVisibleKp = true;
    };
    //广告详情
    let handlePreviewDetail = () => {
      reData.dialogVisibleDetail = true;
    };

    // ==================== 活动规则 start =============================
    // 邀请人活动规则
    let handleYqrRule = () => {
      reData.dialogVisibleYqr = true;
    };
    // 被邀请人活动规则
    let handleByqrRule = () => {
      reData.dialogVisibleYqr = true;
    };
    // 上传邀请人规则
    let handleUploadYqrRule = (option) => {
      let objName = "BannerManagementsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        ruleForm.inviteImageUrl = res.url;
      });
    };
    // 上传被邀请人规则
    let handleUploadByqrRule = (option) => {
      let objName = "BannerManagementsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        ruleForm.invitedImageUrl = res.url;
      });
    };
    // 邀请人规则回显
    let handleImgDeleteYqrRule = () => {
      ruleForm.imgDetailYqr = false;
      ruleForm.inviteImageUrl = null;
      ruleForm.uploadYqrRule = false;
    };
    // 被邀请人规则回显
    let handleImgDeleteByqrRule = () => {
      ruleForm.imgDetailByqr = false;
      ruleForm.invitedImageUrl = null;
      ruleForm.uploadByqrRule = false;
    };
    // ==================== 活动规则 end =============================

    //初始化会员管理
    let queryManageListFun = async (obj) => {
      let arr = await getvipmanage(obj);
      reData.vipManageList = arr;
      if (reData.vipManageList == null || reData.vipManageList.length < 1) {
        //会员管理为空时，会员管理模块隐藏
        reData.vipManageFlag = false;
      } else {
        reData.vipManageFlag = true;
      }
    };
    //全选
    let handleCheckAllChange = (val) => {
      ruleForm.applicationTasks = val ? ["1", "2", "3", "4"] : [];
      reData.isIndeterminate = false;
    };
    let handleCheckedCitiesChange = (value) => {
      let checkedCount = value.length;
      reData.checkAll = checkedCount === reData.applicationTasks.length;
      reData.isIndeterminate =
        checkedCount > 0 && checkedCount < reData.applicationTasks.length;
    };
    //领券规则number校验
    let isNumber = (value, index) => {
      let regExp = /^\d+$/;
      if (!regExp.test(value)) {
        if (index == 1) {
          ruleForm.peopleDayMaxCount = null;
        } else if (index == 2) {
          ruleForm.dayMaxCount = null;
        } else if (index == 3) {
          ruleForm.peopleMaxCount = null;
        } else if (index == 4) {
          ruleForm.accumulatePointsOne = null;
        } else if (index == 6) {
          ruleForm.inviteNumber = null;
        } else {
          ruleForm.accumulatePointsTwo = null;
        }
        ElMessage.error({
          message: "请输入非负整数！",
          duration: 2000,
        });
      }
    };
    //适用城市全国不能和其他城市并存
    let bannerCityChange = (value) => {
      if (value.length > 0) {
        if (value.indexOf("0") > -1) {
          ruleForm.bannerCity = ["0"];
        }
      }
    };
    //表单重置
    let resetForm = () => {
      ruleForm.dialogFormVisible = false;
      reForm();
    };
    //提示信息表单重置
    let resetFormMessage = () => {
      ruleFormMessage.dialogFormVisibleMessage = false;
      ruleFormMessage.evalContent = null;
    };
    //领券数量重置
    let resetFormVip = () => {
      reDataForm.dialogFormVisibleVip = false;
      reDataForm.tableVip = [];
    };
    let reForm = () => {
      if(ruleForm.bannerType == 2) {
        nextTick(() => {
          quillEditorRef.value.$refs["quill-editor"].setHTML("");
        });
      }
      ruleFormRef.value.resetFields();
      //广告上传的广告el-u消除
      reData.bannerPositionListData = [
        //广告位置（1：开屏广告，2：三屏广告A，3：三屏广告B，4：三屏广告C）
        { id: 1, name: "开屏广告" },
        { id: 2, name: "四屏广告" },
        /* {id:3,name:'三屏广告B'},
           {id:4,name:'三屏广告C'}*/
      ];
      reData.timeFlag = false;
      reData.pickerOptions = [];
      reData.dialogVisible = false;
      reData.dialogVisibleKp = false;
      reData.dialogVisibleDetail = false;
      if (reData.vipManageList != null && reData.vipManageList.length > 0) {
        reData.vipManageFlag = true;
      } else {
        reData.vipManageFlag = false;
      }
      reData.isIndeterminate = false;
      reData.checkAll = false;
      reData.radioOne = null;
      reData.radioTwo = null;
      ruleForm.fileList = [];
      ruleForm.fileLists = [];
      ruleForm.transferImgList = [];
      ruleForm.fileListKp = [];
      ruleForm.fileListDetail = [];
      ruleForm.dialogFormVisible = false;
      ruleForm.coupon = [];
      reData.couponListData = [];
      ruleForm.peopleDayMaxCount = null;
      ruleForm.dayMaxCount = null;
      ruleForm.peopleMaxCount = null;
      ruleForm.bannerType = null;
      ruleForm.bannerName = "";
      ruleForm.bannerCity = [];
      ruleForm.bannerPosition = null;
      /*ruleForm.taskTime = null;*/
      ruleForm.bannerStartTime = null;
      ruleForm.bannerImgURL = null;
      ruleForm.applicationTasks = [];
      ruleForm.accumulatePointsOne = null;
      ruleForm.inviteNumber = null;
      ruleForm.accumulatePointsTwo = null;
      ruleForm.couponInformationOne = [];
      ruleForm.couponInformationTwo = [];
      ruleForm.bannerDetails = null;
      ruleForm.status = null;
      ruleForm.shareTitle = null;
      ruleForm.shareImage = null;
      ruleForm.transferImg = null;
      ruleForm.shareDescription = null;
      ruleForm.transferDescribe = null;
      ruleForm.inviteImageUrlFileList = []; // 邀请人规则图片
      ruleForm.inviteImageUrl = null; // 邀请人规则图片
      ruleForm.invitedImageUrlFileList = []; // 被邀请人规则图片
      ruleForm.invitedImageUrl = null; // 被邀请人规则图片
      ruleForm.participants = []; // 邀请人等级
      ruleForm.maxInviteNumber = null; // 最多邀请人数
      ruleForm.minInviteNumber = null; // 最少邀请人数
      ruleForm.registerRule = { // 被邀请人注册
        inviteRuleConfigId: '',
        amountStart: null,
        amountEnd: null,
        inviteRewards: [], // 邀请人奖励
        invitedRewards: [] // 被邀请人奖励
      },
      ruleForm.consumeRules = []; // 被邀请人消费


      ruleForm.activityDescription = ''; // 活动描述
      ruleForm.venue = ''; // 活动地点
      ruleForm.sponsorPhoneNum = ''; // 主办方电话
      ruleForm.couponIds = []; // 优惠券(目前需求是单条,扩展：多条可以逗号拼
      ruleForm.applyTime = null // 举办时间
    };
    //查看明细
    let ViewDetail = (obj) => {
      if (obj["bannerType"] == 1) {
        router.push({
          name: "UserCoupons",
          query: { bannerManagementId: obj.id },
        });
      } else if (obj["bannerType"] == 2) {
        router.push({
          name: "ApplicationProject",
          query: { bannerManagementId: obj.id },
        });
      } else if (obj["bannerType"] == 3) {
        router.push({
          name: "RecommendFriend",
          query: { bannerManagementId: obj.id },
        });
      }
    };
    queryManageListFun();
    //获取项目列表方法
    let getCouponsRuleListFun = async (obj) => {
      let arr = await getCouponsRule(obj);
      reData.couponListData = arr.data.coupon_rule_list;
      if (ruleForm.addOrUpdate == 1 && ruleForm.bannerType == 1) {
        let vipCoupon = await getVipCouponByIdK({
          bannerManagementId: ruleForm.id,
        });
        if (reData.vipManageList != null && reData.vipManageList.length > 0) {
          reData.vipManageFlag = true;
          if (vipCoupon != null && vipCoupon.length > 0) {
            /*for(let idx = 0;idx < vipCoupon.length;idx++){*/
            for (let index = 0; index < reData.vipManageList.length; index++) {
              ruleForm.coupon[index] = vipCoupon
                .filter(
                  (item) => reData.vipManageList[index].id == item.vipManageId
                )
                .map((item) => item.couponInformation)
                .map(Number);
              /*if(vipCoupon[idx].vipManageId == reData.vipManageList[index].id) {
                      ruleForm.coupon[index] = vipCoupon[idx].couponInformation.split(',').map(Number);
                      //将string数组转为int数组
                      break;
                  }*/
              /*}*/
            }
          } else {
            ruleForm.coupon = [];
          }
        } else {
          //会员和优惠券
          ruleForm.coupon = [];
        }
      }
    };
    let couponFocus = async () => {
      if (ruleForm.taskTime == null || ruleForm.taskTime.length < 1) {
        ElMessage.error({
          message: "请先选择活动时间！",
          duration: 2000,
        });
      }
    };
    let countDetail = async (event) => {
      //弹窗并且显示数据
      reDataForm.dialogFormVisibleVip = true; //显示出表单
      optionVip.bannerManagementId = event.id;
      getCounponName(optionVip);
    };
    //声明查询条件
    let optionVip = reactive({
      bannerManagementId: null,
      page: 1,
      pageSize: 5,
    });
    let getCounponName = async (obj) => {
      let tableVip = await getVipCouponById(obj);
      /*data.total = arr.totalCount;*/
      reDataForm.tableVip = tableVip.items;
      reDataForm.totalvip = tableVip.totalCount;
    };
    return {
      showCode,
      oldWithNewFun,
      codeDialogVisible,
      codeBindUrl,
      value,
      activeName,
      tableLoading,
      getVipListDataFun,
      activeNameYqr,
      addInviterAward,
      delInviterAward,
      addConsumptionRule,
      delConsumptionRule,
      changeAmountMax,
      judge,
      handleYqrRule,
      handleByqrRule,
      handleUploadYqrRule,
      handleUploadByqrRule,
      handleImgDeleteYqrRule,
      handleImgDeleteByqrRule,
      lookDetail,
      deleteReward,
      data,
      reData,
      reForm,
      handleCurrentChange,
      handleCurrentChangeVip,
      handleCheckAllChange,
      handleCheckedCitiesChange,
      isNumber,
      imageClose,
      getTabList,
      handlePreviews,
      handlePreviewDetail,
      option,
      optionVip,
      getCounponName,
      ViewDetail,
      handleImgDelete,
      UpdateClick,
      handleUploads,
      transferHandleUpload,
      AddClick,
      indexMethod,
      deleteClick,
      positionFocus,
      positionChange,
      closeStatus,
      getCityListDataFun,
      timeFocus,
      timeChange,
      taskChange,
      applyTimeChange,
      handlePreview,
      baPickerOptions,
      taskTimeOptions,
      handleRemoves,
      bannerCityChange,
      reDataForm,
      ruleForm,
      ruleFormMessage,
      rules,
      ruleFormRef,
      ruleFormRefMessage,
      beforeUpload,
      // handleExceed,
      validatePositiveInteger,
      handleUpload,
      submitForm,
      submitFormMessage,
      resetForm,
      resetFormMessage,
      quillEditorRef,
      resetFormVip,
      couponFocus,
      submitFormVip,
      getCouponsListClick,
      getFormattedTime,
      handlePreviewKp,
      handleImgDeleteKp,
      handleImgDeleteDetail,
      handleUploadKp,
      handleUploadDetail,
      setMessage,
      countDetail,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
::v-deep {
  .yqr_box, .gz_box {
    .el-form-item {
      margin-bottom: 10px !important;
    }
  }
  .el-tabs__item {
    font-size: 16px;
    font-weight: 500;
  }
  .el-tabs__item.is-active {
    color: rgb(75, 184, 179);
  }
  .el-tabs__active-bar {
    background-color: rgb(75, 184, 179);
  }
  .el-tabs__item:hover {
    color: rgb(75, 184, 179);
  }
}

.el-image-viewer__close {
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0) !important;
}

.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;

  >div {
    width: 230px;
    display: flex;
    align-items: center;

    span {
      width: 100px;
    }

    margin-right: 25px;
  }
}

.paginationBox {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
